import { string, object, ref, number } from 'yup';

const schema = object().shape({
  sticker: string().required('SOS Sticker # is required'),
  odometerStart: number()
    .positive('Odometer must be a positive number')
    .typeError('Odometer must be a number')
    .required('Odometer is required'),
  odometerEnd: number()
    .positive('Odometer must be a positive number')
    .typeError('Odometer must be a number')
    .required('Odometer is required')
    .moreThan(ref('odometerStart'), 'Odometer end should be bigger than start')
    .when('odometerStart', (odometerStart, schema) =>
      schema.test(
        'is-greater-than-odometerStart',
        'Odometer end must be within 100 miles of start',
        odometerEnd =>
          typeof odometerStart !== 'number' ? true : odometerEnd <= (odometerStart || 0) + 100
      )
    )
});

export default schema;
