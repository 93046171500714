import moment from 'moment';

const getPercentage = value => {
  return `${Math.round(value * 100)}%`;
};

const transformAll = ({ data }) => {
  const total = data.infos?.total;
  data = data.infos?.responses?.map(
    ({ birthdate, completionDate, road, skills, score, ...rest }) => ({
      ...rest,
      birthdate: moment(birthdate).parseZone().format('MM/DD/YYYY'),
      completionDate: moment(completionDate).parseZone().format('MM/DD/YYYY'),
      road,
      skills,
      score
    })
  );

  return { data, total };
};

const transformAllReports = ({ data }) => {
  const total = data.studentReport?.total;
  data = data.studentReport?.reportData?.map(
    ({
      roadCompletion,
      roadPerformance,
      preTripCompletion,
      preTripPerformance,
      skillsCompletion,
      skillsPerformance,
      locationType,
      ...rest
    }) => ({
      ...rest,
      preTripCompletion: getPercentage(preTripCompletion),
      preTripPerformance: preTripPerformance || '0%',
      roadCompletion: getPercentage(roadCompletion),
      roadPerformance,
      skillsCompletion: getPercentage(skillsCompletion),
      skillsPerformance,
      locationType: locationType === 1 ? 'Yard' : 'Classroom'
    })
  );

  return { data, total };
};

const transformBeforeStudentUpdate = item => {
  const {
    id,
    completionDate,
    road,
    skills,
    score,
    studentNumber,
    birthdate,
    status,
    endorsementCode,
    firstName,
    lastName,
    branchId,
    contactNumber,
    hazmatStartDate,
    branch,
    licenseState
  } = item;

  let data = {
    branchId: endorsementCode === 'H' ? branch : branchId,
    contactNumber: contactNumber,
    firstName,
    lastName,
    id,
    completionDate: moment(completionDate).parseZone().format('YYYY-MM-DD'),
    birthdate: moment(birthdate).parseZone().format('YYYY-MM-DD'),
    studentNumber,
    status,
    ...(road && { road: +road }),
    ...(skills && { skills: +skills }),
    ...(score && endorsementCode !== 'H' && { score: +score }),
    ...(score && endorsementCode === 'H' && { hazmatScore: +score }),
    endorsementCode,
    licenseState
  };

  return data;
};

const transformStaffSubmissions = ({ data }) => {
  const total = data.info?.total;
  data = data.info?.studentInfos?.map(
    ({
      studentFirstName,
      studentLastName,
      submittedByFirstName,
      submittedByLastName,
      locationType,
      createdAt,
      ...rest
    }) => ({
      ...rest,
      studentFirstName,
      studentLastName,
      locationType: locationType === 1 ? 'Yard' : 'Classroom',
      createdAt,
      submittedBy: `${submittedByFirstName} ${submittedByLastName}`
    })
  );

  return { data, total };
};

export {
  transformAll,
  transformBeforeStudentUpdate,
  transformAllReports,
  transformStaffSubmissions
};
