import React, { useContext, useState, useCallback } from 'react';
import { Context } from 'store';
import { Card, Button, Select, Form, notification } from 'antd';
import { PageContainer } from '_src/shared/styles';
import services from '_src/services';
import { Refresh, AsyncTable, AsyncModal } from '_src/components';
import { ButtonContainer, Row } from './styles';
import { reportTypes } from '../../constants';
import { debounce } from '_src/utils';

const { Option } = Select;

export default () => {
  const { setShown } = useContext(Context);

  const config = services['automatedEmails'];
  const resource = 'automatedEmails';
  const extendedReportTypes = [{ id: 0, label: 'All report types' }, ...reportTypes];

  const [initialValues, setInitialValues] = useState({});
  const [reportType, setReportType] = useState('0');
  const [filterParams, setFilterParams] = useState({});
  const [emails, setEmails] = useState([]);

  const onRowClick = record => {
    if (!record.deletedAt) {
      setInitialValues({ ...record });
      setShown(resource);
    }
  };

  const handleEmailChange = values => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const lastValue = values[values.length - 1];

    if (lastValue && !emailRegex.test(lastValue)) {
      notification.warning({
        message: 'Invalid email format!',
        duration: 3
      });
      return;
    }

    setEmails(values);

    if (values.length === 0) {
      const { emails, ...remainingParams } = filterParams;
      debounceCallback(remainingParams);
    } else {
      debounceCallback({ ...filterParams, emails: values });
    }
  };

  const debounceCallback = useCallback(
    debounce(params => {
      setFilterParams(prev => {
        if (!params.emails || params.emails.length === 0) {
          const { emails, ...remainingParams } = prev;
          return remainingParams;
        }
        return { ...prev, emails: params.emails.join(',') };
      });
    }, 1000),
    []
  );

  return (
    <PageContainer>
      <Refresh />
      <Card title={config.title}>
        <ButtonContainer>
          <Row>
            <Form.Item label="Report Type">
              <Select
                style={{ width: '300px' }}
                value={reportType}
                onChange={val => {
                  setReportType(val);
                  if (val == 0) {
                    const { type, ...remainingParams } = filterParams;
                    setFilterParams(remainingParams);
                  } else {
                    setFilterParams({
                      ...filterParams,
                      type: val
                    });
                  }
                }}
              >
                {extendedReportTypes.map(report => (
                  <Option key={report.id}>{report.label}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Recipients">
              <Select
                label="Recipients"
                mode="tags"
                style={{ width: '300px' }}
                placeholder="Enter emails"
                value={emails}
                onChange={handleEmailChange}
                tokenSeparators={[',']}
              >
                {emails.map(email => (
                  <Option key={email}>{email}</Option>
                ))}
              </Select>
            </Form.Item>
          </Row>
          <Button
            type="primary"
            onClick={() => {
              setInitialValues({});
              setShown(resource);
            }}
          >
            {config.createButton}
          </Button>
        </ButtonContainer>
        <AsyncTable
          resource={resource}
          config={{
            ...config,
            table: [...config.table]
          }}
          fileName={resource}
          onRowClick={onRowClick}
          params={filterParams}
          pagination
        />
        <AsyncModal
          showDelete={true}
          saveText="Submit"
          title="Save"
          resource={resource}
          closeModal={() => setShown(resource, false)}
          initialValues={initialValues}
        />
      </Card>
    </PageContainer>
  );
};
