import React from 'react';
import { Tag, Tooltip } from 'antd';

export default [
  {
    title: 'First name',
    dataIndex: 'firstName',
    key: 'firstName',
    render: (_, { firstName }) => <div>{firstName}</div>,
    sorter: true,
    width: 200
  },
  {
    title: 'Last name',
    dataIndex: 'lastName',
    key: 'lastNAme',
    render: (_, { lastName }) => <div> {lastName}</div>,
    sorter: true,
    width: 200
  },
  {
    title: 'Contact number',
    dataIndex: 'contactNumber'
  },
  {
    title: 'Branch name',
    dataIndex: 'branchName',
    sorter: true
  },
  {
    title: 'Location type',
    dataIndex: 'locationType'
  },
  {
    title: 'Sponsor',
    dataIndex: 'sponsor',
    sorter: true,
    width: 200,
    render: sponsors => {
      const sponsorsList = sponsors ? sponsors.split(';').map(s => s.trim()) : [];
      return (
        <Tooltip
          placement="bottom"
          title={
            <div style={{ padding: 10 }}>
              {sponsorsList.map(sponsor => (
                <Tag color="blue" key={sponsor} style={{ marginBottom: 5, display: 'block' }}>
                  {sponsor}
                </Tag>
              ))}
            </div>
          }
        >
          <div style={{ display: 'flex' }}>
            <span
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 200
              }}
            >
              {sponsorsList[0]}
            </span>
            {sponsorsList.length > 1 && (
              <span style={{ cursor: 'pointer', color: '#1890ff' }}>
                {' '}
                &nbsp;+{sponsorsList.length - 1}
              </span>
            )}
          </div>
        </Tooltip>
      );
    }
  },
  {
    title: 'Pre trip completion',
    dataIndex: 'preTripCompletion'
  },
  {
    title: 'Pre trip performance',
    dataIndex: 'preTripPerformance'
  },
  {
    title: 'Total pre trip hours',
    dataIndex: 'totalPreTripHours'
  },
  {
    title: 'Road completion',
    dataIndex: 'roadCompletion'
  },
  {
    title: 'Road performance',
    dataIndex: 'roadPerformance'
  },
  {
    title: 'Total road observation hours',
    dataIndex: 'totalRoadObservationHours'
  },
  {
    title: 'Total road hours',
    dataIndex: 'totalRoadHours'
  },
  {
    title: 'Skills completion',
    dataIndex: 'skillsCompletion'
  },
  {
    title: 'Skills performance',
    dataIndex: 'skillsPerformance'
  },
  {
    title: 'Total skill observation hours',
    dataIndex: 'totalSkillObservationHours'
  },
  {
    title: 'Total skill hours',
    dataIndex: 'totalSkillHours'
  },
  {
    title: 'Total class hours',
    dataIndex: 'totalClassHours'
  },
  {
    title: 'Total training hours',
    dataIndex: 'totalTrainingHours'
  }
];
