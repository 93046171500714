import React from 'react';

import moment from 'moment';

export default [
  {
    title: 'Code',
    dataIndex: 'code',
    width: 100
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    width: 120,
    sorter: true
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    width: 120,
    sorter: true
  },
  {
    title: 'Email',
    dataIndex: 'email',
    width: 270,
    sorter: true
  },
  {
    title: 'Employers',
    key: 'employer',
    width: 150,
    render: (_, { employer }) => (
      <div style={{ maxWidth: 150, whiteSpace: 'break-spaces' }}>{employer}</div>
    )
  },
  {
    title: 'Testing Date',
    key: 'testing_date',
    width: 120,
    dataIndex: 'testingDate',
    render: (_, { testingDate }) =>
      testingDate ? moment(testingDate).parseZone().format('MM/DD/YYYY') : '',
    sorter: true
  },
  {
    title: 'Instructor First Name',
    dataIndex: 'instructorFirstName',
    width: 120,
    sorter: true
  },
  {
    title: 'Instructor Last Name',
    dataIndex: 'instructorLastName',
    width: 120,
    sorter: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 120,
    filters: [
      { text: 'Successful', value: 'Successful' },
      { text: 'Incomplete', value: 'Incomplete' },
      { text: 'N/A Performance', value: 'n_a_performance' }
    ]
  },
  {
    title: 'Incomplete Reason',
    dataIndex: 'incompleteReason',
    width: 200,
    render: (_, { incompleteReason }) => (
      <div style={{ maxWidth: 200, whiteSpace: 'break-spaces' }}>{incompleteReason}</div>
    ),
    sorter: true
  },
  {
    title: 'Score',
    dataIndex: 'score',
    sorter: true,
    width: 110
  },
  {
    title: 'Sections',
    children: [
      {
        title: 'Attention Signaling',
        key: 'attention_signaling',
        width: 100,
        render: (_, { sections }) => sections.attentionSignaling
      },
      {
        title: 'Inspection Safety',
        key: 'inspection_safety',
        width: 100,
        render: (_, { sections }) => sections.inspectionSafety
      },
      {
        title: 'Parking Docking Backing',
        key: 'parking_docking_backing',
        width: 100,
        render: (_, { sections }) => sections.parkingDockingBacking
      },
      {
        title: 'Shifting',
        key: 'shifting',
        width: 100,
        render: (_, { sections }) => sections.shifting
      },
      {
        title: 'Soft Skills',
        key: 'soft_skills',
        width: 100,
        render: (_, { sections }) => sections.softSkills
      },
      {
        title: 'Speed Stopping Accelerating',
        key: 'speed_stopping_accelerating',
        width: 100,
        render: (_, { sections }) => sections.speedStoppingAccelerating
      },
      {
        title: 'Turns',
        key: 'turns',
        width: 100,
        render: (_, { sections }) => sections.turns
      }
    ]
  },
  {
    title: 'Feedback Notes',
    key: 'feedback_notes',
    width: 200,
    render: (_, { feedbackNotes }) => (
      <div style={{ maxWidth: 200, whiteSpace: 'break-spaces' }}>{feedbackNotes}</div>
    )
  }
];
