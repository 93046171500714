export default [
  {
    title: 'Student Name',
    dataIndex: 'studentName',
    key: 'studentName'
  },
  {
    title: 'Instructor',
    dataIndex: 'instructor',
    key: 'status'
  },
  {
    title: 'Deleter',
    dataIndex: 'deleter',
    key: 'deleter'
  },
  {
    title: 'Test type',
    dataIndex: 'testType',
    key: 'testType'
  },
  {
    title: 'Attendance deleted date',
    dataIndex: 'attendanceDeletedDate',
    key: 'attendanceDeletedDate'
  },
  {
    title: 'Test deleted date',
    dataIndex: 'testDeletedDate',
    key: 'testDeletedDate'
  }
];
