import React from 'react';

export default [
  {
    title: 'Permit rec. date',
    dataIndex: 'permitRecDate',
    sorter: true
    // sorter: (a, b) => {
    //   let dateA = new Date(a.permitRecDate);
    //   let dateB = new Date(b.permitRecDate);
    //   if (!a.date && b.date) return 1;
    //   else if (a.date && !b.date) return -1;
    //   else if (dateA === dateB) return 0;
    //   else return dateA > dateB ? 1 : dateB > dateA ? -1 : 0;
    // },
    // width: 200
  },
  {
    title: 'Branch name',
    dataIndex: 'branchName',
    sorter: true
    // sorter: (a, b) => a.branchName.localeCompare(b.branchName)
    // width: 200
  },
  {
    title: 'Date class begins',
    dataIndex: 'dateClassBegins'
    // width: 200
  },
  {
    title: 'Branch state code',
    dataIndex: 'branchStateCode'
    // width: 200
  },
  {
    title: 'Location',
    dataIndex: 'location'
    // width: 200
  },
  {
    title: 'First name',
    dataIndex: 'firstName',
    key: 'firstName',
    render: (_, { firstName }) => (
      <div>{firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase()}</div>
    ),
    sorter: true
    // sorter: (a, b) => a.firstName.localeCompare(b.firstName)
    // width: 200
  },

  {
    title: 'Last name',
    dataIndex: 'lastName',
    key: 'lastNAme',
    render: (_, { lastName }) => (
      <div> {lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase()}</div>
    ),
    sorter: true
    // sorter: (a, b) => a.lastName.localeCompare(b.lastName)
    // width: 200
  },

  {
    title: 'Birthdate',
    dataIndex: 'birthdate'
    // width: 200
  },
  {
    title: 'License',
    dataIndex: 'studentNumber',
    width: 200
  },
  {
    title: 'Endorsement code',
    dataIndex: 'endorsementCode'
    // width: 200
  },
  {
    title: 'Completion date',
    dataIndex: 'completionDate'
    // width: 200
  },
  {
    title: 'Contact number',
    dataIndex: 'contactNumber'
    // width: 200
  },
  {
    title: 'Sponsor',
    dataIndex: 'sponsor',
    sorter: true,
    // sorter: (a, b) => a.sponsor.localeCompare(b.sponsor),
    width: 200
  },
  {
    title: 'FMCSA Location  Id',
    dataIndex: 'fmcsaLocationId',
    width: 300
  },
  {
    title: 'Status',
    dataIndex: 'status',
    sorter: true
    // sorter: (a, b) => a.status.localeCompare(b.status)
    // width: 200
  },
  {
    title: 'Road',
    dataIndex: 'road'
    // width: 200
  },
  {
    title: 'Skills',
    dataIndex: 'skills'
    // width: 200
  },
  {
    title: 'Score',
    dataIndex: 'score'
    // width: 200
  }
];
