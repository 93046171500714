export default {
  attendance: [
    {
      key: 'attendance',
      label: 'Attendance',
      path: '/attendance',
      restrict: 'attendance'
    }
  ],

  studentGroups: [
    {
      key: 'studentGroups',
      label: 'Student Groups',
      path: '/studentGroups',
      restrict: 'students/groups'
    }
  ],

  evaluationTests: [
    {
      key: 'evaluationTests',
      label: 'Edit Evaluation Test',
      path: '/evaluationTests',
      restrict: 'admin/testing'
    }
  ],
  organization: [
    {
      key: 'organization',
      label: 'Organization',
      subItems: [
        { key: 'regions', label: 'Regions', path: '/regions', restrict: 'regions' },
        { key: 'branches', label: 'Branches', path: '/branches', restrict: 'branches' }
      ],
      restrict: ['regions', 'branches', 'classrooms', 'yards']
    }
  ],
  userManagement: [
    {
      key: 'userManagement',
      label: 'User Management',
      subItems: [
        // { key: 'groups', label: 'Groups', path: '/groups', restrict: 'groups' },
        { key: 'users', label: 'Users', path: '/users', restrict: 'users' }
      ],
      restrict: ['groups', 'restrict']
    }
  ],
  allReports: [
    {
      key: 'reports',
      label: 'Reports',
      path: '/reports',
      restrict: 'reports/attendance'
    },
    {
      key: 'instructorReports',
      label: 'Instructor Reports',
      path: '/instructorReports',
      restrict: 'reports/attendance'
    },
    {
      key: 'customReports',
      label: 'Custom Reports',
      path: '/customReports'
    },
    {
      key: 'lmsUsage',
      label: 'LMS Usage',
      path: '/lmsUsage',
      restrict: 'reports/usage'
    },
    {
      key: 'submissionReport',
      label: 'Submission Report',
      path: '/submissionReport'
    },
    {
      key: 'userActivityLogs',
      label: 'User Activity Logs',
      path: '/userActivityLogs'
    }
  ],
  records: [
    {
      key: 'records',
      label: 'Student Records',
      path: '/records'
    }
  ],
  settings: [
    {
      key: 'profile',
      label: 'Profile',
      path: '/profile'
    },
    {
      key: 'characterQuestions',
      label: 'Character Questions',
      path: '/characterQuestions',
      restrict: 'testing/questions'
    },
    {
      key: 'roadQuestions',
      label: 'Road Questions',
      path: '/roadQuestions',
      restrict: 'testing/questions'
    },
    {
      key: 'preTrips',
      label: 'Pre-trip Questions',
      path: '/preTrips',
      restrict: 'testing/questions'
    },
    {
      key: 'skillQuestions',
      label: 'Skill Questions',
      path: '/skillQuestions',
      restrict: 'testing/questions'
    },
    {
      key: 'automatedEmails',
      label: 'Automated Emails',
      path: '/emails',
      restrict: 'reports/automated_reports'
    }
  ],
  driverScore: [
    {
      key: 'driversScore',
      label: 'Driver Scoring & AFP',
      subItems: [
        {
          key: 'afpDocuments',
          label: 'AFP Documents',
          path: '/documents',
          restrict: 'afp_documents'
        },
        {
          key: 'afpDriversReport',
          label: 'AFP Drivers Report',
          path: '/driversReport',
          restrict: ['scores/drivers'],
          method: 'POST'
        },
        {
          key: 'driversList',
          label: 'Drivers List',
          path: '/drivers',
          restrict: ['driver/scores/search', 'driver/scores/certificate/download']
        },
        {
          key: 'driverScoringReport',
          label: 'Driver Scoring Report',
          path: '/driversReport',
          restrict: ['scores/drivers'],
          method: 'POST'
        },
        {
          key: 'employersList',
          label: 'Employers List',
          path: '/employers',
          restrict: [
            'driver/scores/search',
            'driver/scores/certificate/download',
            'reports/automated_reports'
          ]
        }
      ]
    }
  ],
  bulkAttendance: [
    {
      key: 'bulkAttendance',
      label: 'Bulk Attendance',
      path: '/bulkAttendance',
      restrict: 'admin/attendance'
    }
  ],
  bulkYardManagement: [
    {
      key: 'bulkYardManagement',
      label: 'Student Yard Location',
      path: '/studentYardLocation',
      restrict: 'admin/attendance'
    }
  ],
  activityLogs: [
    {
      key: 'activityLogs',
      label: 'Activity Logs',
      path: '/activity-logs',
      restrict: 'users',
      method: 'POST'
    }
  ],
  manageLocationType: [
    {
      key: 'manageLocationType',
      label: 'Manage Location',
      path: '/manage-location-type',
      restrict: 'users',
      method: 'POST'
    }
  ],
  yardSchedule: [
    {
      key: 'yardSchedule',
      label: 'Yard Schedule',
      path: '/yard-schedule',
      restrict: 'users',
      method: 'POST'
    }
  ],
  manageDuplicates: [
    {
      key: 'manageDuplicates',
      label: 'Manage Duplicates',
      path: '/manage-duplicates',
      restrict: 'users',
      method: 'POST'
    }
  ],
  classroomScore: [
    {
      key: 'classroomScore',
      label: 'Classroom Score',
      path: '/classroomScore',
      restrict: 'fmcsa'
    }
  ],
  fmcsaSubmissions: [
    {
      key: 'fmcsaSubmissions',
      label: 'FMCSA Submissions',
      path: '/fmcsaSubmissions',
      restrict: 'fmcsa'
    }
  ],
  cdlTesting: [
    {
      key: 'cdlTesting',
      label: 'CDL Testing',
      path: '/cdlTesting',
      restrict: 'cdl'
    }
  ],
  studentAgreements: [
    {
      key: 'studentAgreements',
      label: 'Hazmat Portal',
      path: '/studentAgreements',
      restrict: 'hazmat'
    }
  ],
  studentIntervention: [
    {
      key: 'studentIntervention',
      label: 'Student Intervention',
      path: '/studentIntervention',
      restrict: 'student_intervention'
    }
  ]
};
