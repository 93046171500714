import React, { useState, useContext, useEffect } from 'react';
import { Request, Context, useSave } from 'store';

import { notification } from 'antd';
import { getPath } from 'utils';
import { useHistory } from 'react-router-dom';

import { Table } from '_src/components';
import { Input, Select } from '_src/shared/form-helpers';
import services from '_src/services';

const typeToComponent = {
  input: Input,
  select: Select
};

const FormItem = ({ initialValue, component, save, name, id }) => {
  const [formValue, setValue] = useState(initialValue);
  const { type, ...componentProps } = component;
  const Component = typeToComponent[type];
  let events = {};

  if (type === 'select') {
    events.onChange = value => {
      setValue(value);
      save({ id, [name]: value });
    };
  } else {
    events.onBlur = () => {
      if (formValue !== initialValue) {
        save({ id, [name]: formValue });
      }
    };
  }

  return <Component {...componentProps} value={formValue} onChange={setValue} {...events} />;
};

const getPageNumber = () => {
  if (sessionStorage && parseInt(sessionStorage.getItem('PAGE_NUMBER')) > 0) {
    return parseInt(sessionStorage.getItem('PAGE_NUMBER'));
  }
  return 1;
};

export default ({
  resource,
  config,
  onRowClick = () => {},
  onChangePage = () => {},
  onTotal = () => {},
  onFirstRecord = () => {},
  onStudentIds = () => {},
  params = {},
  pagination,
  resetPageOn = [],
  resetPageOnFilter = [],
  customPagination = false,
  aamva = null,
  ...rest
}) => {
  // handle preserve page number on reload
  const history = useHistory();
  useEffect(() => {
    return history.listen(() => {
      sessionStorage.setItem('PAGE_NUMBER', 1);
      setPage(1);
    });
  }, [history]);

  config = config || services[resource];
  let urlConfig;

  switch (resource) {
    case 'fmcsaReports':
      urlConfig = config.getUrl;
      break;
    case 'staffSubmissions':
      urlConfig = config.getStaffSubmissions;
      break;
    default:
      urlConfig = config.get;
  }
  const configParams = config.get.params;
  const { refreshResource, getData } = useContext(Context);
  const [oldParams, setOldParams] = useState(params);
  const [oldFilters, setOldFilters] = useState(resetPageOnFilter);
  const [total, setTotal] = useState(null);
  const [firstRecord, setFirstRecord] = useState(null);
  const [studentIds, setStudentIds] = useState(null);
  const pageSize = 20;
  const [page, setPage] = useState(getPageNumber());

  useEffect(() => {
    const shouldUpdateFilter = oldFilters.filter(x => !resetPageOnFilter.includes(x));
    const shouldUpdate = resetPageOn.some(field => params[field] !== oldParams[field]);

    if (shouldUpdate || shouldUpdateFilter) {
      sessionStorage.setItem('PAGE_NUMBER', 1);
      setPage(1);
    }

    setOldParams(params);
    setOldFilters(resetPageOnFilter);
  }, [Object.values(params).join('')]);

  useEffect(() => {
    onTotal(total);
  }, [total]);

  useEffect(() => {
    onFirstRecord(firstRecord);
  }, [firstRecord]);

  useEffect(() => {
    onStudentIds(studentIds);
  }, [studentIds]);

  useEffect(() => {
    const shouldUpdate = resetPageOn.some(field => params[field] !== oldParams[field]);
    const currentPage = shouldUpdate ? 1 : page;
    let skip = 0;
    if (customPagination) {
      skip = currentPage;
    } else {
      skip = (currentPage - 1) * pageSize;
    }
    onChangePage(skip);
  }, [page]);

  const getParams = () => {
    const shouldUpdate = resetPageOn.some(field => params[field] !== oldParams[field]);
    const currentPage = shouldUpdate ? 1 : page;

    let resourceParams = {
      limit: pageSize,
      ...(aamva !== null && { aamva }),
      ...configParams,
      ...params
    };

    if (customPagination) {
      resourceParams.page = currentPage;
    } else {
      resourceParams.skip = (currentPage - 1) * pageSize;
    }

    if (Array.isArray(params) && Array.isArray(configParams)) {
      resourceParams = params.map((paramsObj, index) => ({ ...configParams[index], ...paramsObj }));
    } else if (Array.isArray(params)) {
      resourceParams = params.map(paramsObj => ({ ...configParams, ...paramsObj }));
    } else if (Array.isArray(configParams)) {
      resourceParams = configParams.map(paramsObj => ({ ...params, ...paramsObj }));
    }

    return resourceParams;
  };

  if (config.edit) {
    var { save } = useSave(config.edit);
  }

  if (pagination) {
    pagination = {
      current: page,
      defaultPageSize: pageSize,
      onChange: page => {
        setPage(page);
        sessionStorage.setItem('PAGE_NUMBER', page);
      },
      total: 0
    };
  }

  const saveResource = data => {
    save(data).then(res => {
      if (res.success === 1) {
        notification.success({
          message: `Successfully updated ${config.title.toLowerCase()}`,
          duration: 3
        });
        refreshResource(resource);
      } else {
        notification.error({
          message: `Failed to update ${config.title.toLowerCase()}`,
          duration: 3
        });
      }
    });
  };

  const tableConfig = config.table.map(({ component, componentProps, ...rest }) => {
    if (component) {
      return {
        ...rest,
        render: (text, record) => (
          <FormItem
            initialValue={text}
            component={component}
            name={rest.dataIndex}
            id={record.id}
            save={saveResource}
          />
        )
      };
    }

    return rest;
  });

  const getTableData = data => {
    const dataToReturn = config.tableAccessKey ? getPath(data, 'users') : data;
    setTotal(pagination && dataToReturn ? dataToReturn.total : null);
    setFirstRecord(pagination && dataToReturn && dataToReturn.data ? dataToReturn.data[0] : null);
    if (resource === 'fmcsaSubmissions' && dataToReturn && dataToReturn.data) {
      const currentIds = dataToReturn.data.map(s => s.studentId);
      setStudentIds(currentIds.join());
    }

    return pagination && dataToReturn && dataToReturn.data ? dataToReturn.data : dataToReturn;
  };

  const getPaginationConfig = data => {
    if (pagination) {
      return { ...pagination, total: data.total };
    }

    return pagination;
  };

  return (
    <Request.Get {...urlConfig} params={getParams()}>
      {({ data, busy }) => (
        <Table
          {...rest}
          {...config.tableConfig}
          sortable={config.sortable}
          loading={busy}
          data={getTableData(data)}
          columns={tableConfig}
          onClick={record => {
            const { showEditModal = true } = config;

            if (showEditModal) {
              onRowClick(record);
            }
          }}
          pagination={getPaginationConfig(data)}
        />
      )}
    </Request.Get>
  );
};
