import React, { useState, useCallback, useContext, useEffect } from 'react';
import { Input, Icon, Button, Card, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import { useGet, Context, useFetch } from 'store';

import FilterModal from './components/FilterModal';
import StatItem from './components/StatItem';
import logoBadge from '_src/assets/images/logo-badge.png';
import { PageContainer } from '_src/shared/styles';
import { AsyncTable, Loading } from '_src/components';
import { debounce, isEmptyObj, filterObjectEmptyValues } from '_src/utils';
import services from '_src/services';
import { Refresh } from '_src/components';
import {
  Header,
  Logo,
  Title,
  HeaderStatsWrapper,
  StatsContainer,
  SearchLabel,
  SearchWrapper,
  FilterWrapper
} from './styles';
import { PersistentRepo } from '_src/shared/repo';
const { Option } = Select;

function StudentRecords() {
  const { getData } = useContext(Context);
  const baseApi = GLOBALS.BASE_API;
  const path = 'reports/student_records/students_csv';

  const getInitialLocation = () => {
    const location = PersistentRepo.get('location');

    const user = getData('user');
    const branchId = user.branchId || 1;

    return location ? location : `allxallx${branchId}`;
  };

  const getInitialId = type => {
    const [locationType, locationId, branchId] = getInitialLocation().split('x');
    return type === 'locationType' ? locationType : branchId;
  };

  const resource = 'studentRecords';
  const config = services[resource];

  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [filterParams, setFilterParams] = useState({
    ...filterParams,
    selectedLocation: getInitialId('branchId'),
    locationType:
      getInitialId('locationType') === 'all' ? 0 : getInitialId('locationType') === 'yard' ? 1 : 2
  });
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState(getInitialLocation());
  const [skip, setCurrentSkip] = useState(0);

  const { data: recordsStats, busy: isLoading } = useGet(config.getStats);
  const { get: getLocations, busy } = useFetch({ ...config.getLocations });

  useEffect(() => {
    getLocations().then(data => {
      setLocations(data);
    });
  }, []);

  const history = useHistory();

  const debounceCallback = useCallback(
    debounce(
      (val, fieldName) => {
        setFilterParams(prevState => {
          return {
            ...prevState,
            [fieldName]: val
          };
        });
      },
      1500,
      false
    ),
    []
  );

  const getQueryParams = () => {
    let params;

    if (filterParams.contactNumber) {
      params = filterObjectEmptyValues({
        contact_number: filterParams.contactNumber,
        order_by: filterParams.orderBy,
        order: filterParams.order
      });
    } else {
      params = filterObjectEmptyValues({
        full_name: filterParams.fullName,
        contact_number: filterParams.contactNumber,
        permit: filterParams.hasPermit,
        branch_id: filterParams.selectedLocation,
        location_type: filterParams.locationType,
        status: filterParams.statuses,
        order_by: filterParams.orderBy,
        order: filterParams.order
      });
    }

    var queryString = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&');

    return queryString;
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <PageContainer>
      <Refresh />
      <Card bodyStyle={{ paddingTop: 0 }}>
        <Header>
          <Logo src={logoBadge} />
          <HeaderStatsWrapper>
            <Title>Student Records</Title>
            <StatsContainer>
              <StatItem label="Total Students" value={recordsStats.studentsNumber} />
              <StatItem label="Graduated Students" value={recordsStats.studentsGraduatedNumber} />
              <StatItem label="Enrolled Students" value={recordsStats.studentsEnrolledNumber} />
              <StatItem label="Classroom" value={recordsStats.studentsClassroom} />
              <StatItem label="Yard" value={recordsStats.studentsYard} />
            </StatsContainer>
          </HeaderStatsWrapper>
        </Header>
        <SearchLabel>Find a Student:</SearchLabel>
        <FilterWrapper></FilterWrapper>
        <SearchWrapper>
          <Select
            size="large"
            showSearch
            loading={busy}
            placeholder="Choose a Location"
            optionFilterProp="children"
            value={location}
            onChange={locationSelected => {
              setLocation(locationSelected);
              PersistentRepo.set('location', locationSelected);
              PersistentRepo.delete('allBranches');
              const [locationType, locationId, branchId] = locationSelected.split('x');
              setFilterParams(prevState => {
                return {
                  ...prevState,
                  selectedLocation: locationSelected ? branchId : getInitialId('branchId'),
                  locationType: locationSelected
                    ? locationType === 'all'
                      ? 0
                      : locationType === 'yard'
                      ? 1
                      : 2
                    : getInitialId('locationType')
                };
              });
            }}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{
              marginRight: 15,
              width: '100%',
              minWidth: 150,
              maxWidth: '20%'
            }}
          >
            {locations.map(location => (
              <Option key={location.id} value={location.id}>
                {location.label}
              </Option>
            ))}
          </Select>
          <Input
            size="large"
            placeholder="Search by first or last name"
            allowClear
            onChange={e => {
              debounceCallback(e.target.value, 'fullName');
            }}
            style={{
              marginRight: 15
            }}
            prefix={<Icon type="search" style={{ color: '#909090' }} />}
          />
          <Input
            size="large"
            placeholder="Search by contact number"
            allowClear
            onChange={e => {
              debounceCallback(e.target.value, 'contactNumber');
            }}
            prefix={<Icon type="search" style={{ color: '#909090' }} />}
          />
          <Button
            type="primary"
            style={{
              backgroundColor: '#E55F17',
              borderColor: '#E55F17',
              marginLeft: 15,
              width: '100%',
              minWidth: 120,
              maxWidth: '15%'
            }}
            onClick={() => {
              setFilterModalVisible(true);
            }}
            icon="filter"
            size="large"
          >
            Filter Results
          </Button>
          <Button
            type="primary"
            style={{
              backgroundColor: '#E55F17',
              borderColor: '#E55F17',
              marginLeft: 15,
              width: '100%',
              minWidth: 120,
              maxWidth: '15%'
            }}
            onClick={() => {
              console.log(`${baseApi}/${path}?limit=20&skip=${skip}&${getQueryParams()}`);
              window.location.href = `${baseApi}/${path}?limit=20&skip=${skip}&${getQueryParams()}`;
            }}
            icon="download"
            size="large"
          >
            Download CSV
          </Button>
        </SearchWrapper>
        <AsyncTable
          pagination
          onChange={(pagination, filters, sorter) => {
            // Dont reset page if no sorter has been applied
            if (isEmptyObj(sorter)) {
              return;
            }
            setFilterParams(prevState => {
              return {
                ...prevState,
                orderBy: sorter.column?.dbFieldName || '',
                order: sorter.order === 'ascend' ? 'asc' : 'desc'
              };
            });
          }}
          resetPageOn={[
            'fullName',
            'permit',
            'branchId',
            'locationType',
            'status',
            'orderBy',
            'order'
          ]}
          params={(() => {
            const baseParams = filterObjectEmptyValues({
              fullName: filterParams.fullName,
              contactNumber: filterParams.contactNumber,
              permit: filterParams.hasPermit,
              branchId: filterParams.selectedLocation,
              locationType:
                filterParams.studentTypes !== undefined
                  ? filterParams.studentTypes
                  : filterParams.locationType,
              status: filterParams.statuses,
              orderBy: filterParams.orderBy,
              order: filterParams.order
            });

            if (baseParams.contactNumber) {
              return {
                contactNumber: baseParams.contactNumber,
                orderBy: baseParams.orderBy,
                order: baseParams.order
              };
            }
            return baseParams;
          })()}
          fileName={resource}
          resource={resource}
          onRow={rowData => {
            return {
              onClick: () => {
                history.push(`/records/${rowData.id}`);
              }
            };
          }}
          onChangePage={setCurrentSkip}
        />
      </Card>

      <FilterModal
        visible={filterModalVisible}
        onClose={() => setFilterModalVisible(false)}
        onFilter={params => {
          setFilterParams(prevState => {
            return {
              ...prevState,
              ...params
            };
          });
        }}
      />
    </PageContainer>
  );
}

export default StudentRecords;
