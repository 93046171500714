import { string, object, array } from 'yup';

const schema = object().shape({
  name: string().required('Name is required').max(250, 'Title cannot exceed 250 characters'),
  type: string().required('Type is required'),
  frequency: string().required('Frequency is required'),
  emails: array().required('Add at least one email'),
  branches: array().nullable(),
  sponsors: array()
    .nullable()
    .when('type', {
      is: '3', // When 'type' equals '3'.
      then: array()
        .of(string().required('Sponsor is required')) // Ensures all elements in the array are strings and not null/undefined.
        .required('Amazon sponsors are required for Amazon Report type')
        .test('contains-amazon', 'Only Amazon sponsors allowed for Amazon Report type', sponsors =>
          sponsors ? sponsors.every(sponsor => sponsor.toLowerCase().includes('amazon')) : true
        ),
      otherwise: array().nullable()
    }),
  subject: string().required('Subject is required')
});

export default { create: schema, edit: schema };
