import moment from 'moment';
import { PersistentRepo } from '_src/shared/repo';

const transform = res => {
  const getTimezone = PersistentRepo.get('getTimezone');

  const data = res?.data?.studentReport?.clockinResult || [];

  const transformedData = data.map(item => {
    return {
      ...item,
      testDeletedDate: item.testDeletedDate ?? 'N/A',
      deleter: item.deleter ?? 'N/A',
      attendanceDeletedDate: moment
        .tz(
          moment.parseZone(moment(item.attendanceDeletedDate)).format('MM/DD/YYYY hh:mm A'),
          'MM/DD/YYYY hh:mm A',
          getTimezone
        )
        .format('MM/DD/YYYY hh:mm A'),
      testDeletedDate: item.testDeletedDate
        ? moment
            .tz(
              moment.parseZone(moment(item.testDeletedDate)).format('MM/DD/YYYY hh:mm A'),
              'MM/DD/YYYY hh:mm A',
              getTimezone
            )
            .format('MM/DD/YYYY hh:mm A')
        : 'N/A'
    };
  });

  return { data: transformedData, total: 100 };
};

export { transform };
