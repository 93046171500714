import table from './table';
import { transform } from './transform';

const name = 'userActivityLogs';
const path = 'reports/clockin_reports_data';

export default {
  table,
  get: {
    name,
    path,
    transform,
    defaultValue: []
  },
  downloadUserActivityLogs: {
    path
  }
};
