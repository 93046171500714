const studentRecordsBranchId = [1, 2, 8, 9, 11, 12, 14, 25, 31, 47, 99, 92, 91];

const reportTypes = [
  { id: 1, label: 'Student Progress' },
  { id: 2, label: 'Daily Attendance Report' },
  { id: 3, label: 'Amazon Report' },
  { id: 4, label: 'TPR Report' }
];

const reportTypesDescriptions = [
  {
    id: 1,
    description:
      "This report provides recipients with an overview of their students' progress in the selected fields. It includes a link to the students' report cards, which expires after 24 hours."
  },
  {
    id: 2,
    description:
      "This report offers recipients insights into their students' daily attendance and progress in the selected fields. It includes a link to the students' report cards, which expires after 24 hours, and also features an attachment of the report."
  },
  {
    id: 3,
    description:
      'This report includes an attached file that details the progress of Amazon students.'
  },
  {
    id: 4,
    description: 'This report outlines the students submitted to the TPR/FMCSA portal.'
  }
];

export { studentRecordsBranchId, reportTypes, reportTypesDescriptions };
