import React, { useState, useEffect, useContext } from 'react';
import { useFetch, Context } from 'store';

import { Button, Icon, notification } from 'antd';

import { AsyncTable } from '_src/components';
import { PersistentRepo } from '_src/shared/repo';
import { objToSnakeCase } from '_src/utils';
import services from '_src/services';
import { Select } from '_src/shared/form-helpers';
import { PageWrapper, ActionRow } from '_src/shared/styles';
import qs from 'qs';
import axios from 'axios';

import { PageTitle, Search } from './styles';

export default () => {
  const baseApi = GLOBALS.BASE_API;
  const resource = 'userActivityLogs';
  const config = services[resource];
  const locationsConfig = services['studentRecords'];

  const { getData } = useContext(Context);

  const getInitialLocation = () => {
    const allBranchesSelected = PersistentRepo.get('allBranches');

    const location = allBranchesSelected ? allBranchesSelected : PersistentRepo.get('location');
    const user = getData('user');
    const branchId = user.branchId || 1;

    return location ? location : `allxallx${branchId}`;
  };

  const getInitialId = type => {
    const [locationType, locationId, branchId] = getInitialLocation().split('x');
    return type === 'locationType' ? locationType : branchId;
  };

  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState(getInitialLocation());
  const [loading, setLoading] = useState(true);
  const [locType, setLocType] = useState(0);
  const [skip, setSkip] = useState(0);

  const { get: getLocations, busy } = useFetch({ ...locationsConfig.getLocations });

  useEffect(() => {
    getLocations().then(data => {
      setLocations([{ id: `all`, label: `All Branches` }, ...data]);
      setLoading(false);
    });
  }, []);

  const [filterParams, setFilterParams] = useState({
    limit: 20,
    skip,
    branchId: getInitialId('branchId'),
    locationType: locType
      ? locType
      : getInitialId('locationType') === 'all'
      ? 0
      : getInitialId('locationType') === 'yard'
      ? 1
      : 2
  });

  const downloadReport = async () => {
    const queryParams = qs.stringify(objToSnakeCase({ ...filterParams, skip }), {
      arrayFormat: 'comma'
    });

    try {
      const response = await axios.get(`${baseApi}/reports/clockin_reports?${queryParams}`, {
        responseType: 'blob'
      });

      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = 'clockin_report.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      notification.error({
        message: 'Download Failed !',
        duration: 3
      });
    }
  };

  return (
    <PageWrapper>
      <PageTitle>User Activity Logs & Student actions</PageTitle>

      <Search>
        <Select
          label="Branches"
          showSearch
          onChange={locationSelected => {
            setLocation(locationSelected);
            if (locationSelected === 'all') {
              setFilterParams({ limit: filterParams.limit, skip: filterParams.skip });
              PersistentRepo.set('allBranches', locationSelected);
            } else {
              const [locationType, locationId, branchId] = locationSelected.split('x');
              setFilterParams(() => {
                return {
                  ...filterParams,
                  branchId: locationSelected ? branchId : getInitialId('branchId'),
                  locationType: locationSelected
                    ? locationType === 'all'
                      ? 0
                      : locationType === 'yard'
                      ? 1
                      : 2
                    : getInitialId('locationType')
                };
              });
              PersistentRepo.set('location', locationSelected);
              PersistentRepo.delete('allBranches');
            }
          }}
          options={locations}
          value={location}
        />
      </Search>

      <ActionRow style={{ justifyContent: 'flex-start', marginBottom: '10px' }}>
        <Button type="default" onClick={() => downloadReport()}>
          <Icon type="download" /> Download report
        </Button>
      </ActionRow>

      <div style={{ overflowX: 'auto' }}>
        <AsyncTable
          resource={resource}
          pagination
          config={{
            ...config,
            table: [
              ...config.table,
              {
                title: 'Location',
                render: (_, { branchId, locationType }) => {
                  const a = locations.find(
                    loc => branchId === loc.branchId && locationType === loc.type
                  );

                  return a?.label;
                }
              }
            ]
          }}
          params={filterParams}
          fileName={resource}
          onChangePage={setSkip}
        />
      </div>
    </PageWrapper>
  );
};
