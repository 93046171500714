import { string, object } from 'yup';

const schema = object({
  email: string().required('Email is required').email('Email is not valid'),
  firstName: string()
    .required('First Name is required')
    .min(1, 'First Name cannot be empty')
    .trim()
    .matches(/^[a-zA-Z\s]+$/, 'First Name must only contain letters'),
  lastName: string()
    .required('Last Name is required')
    .min(1, 'Last Name cannot be empty')
    .trim()
    .matches(/^[a-zA-Z\s]+$/, 'Last Name must only contain letters'),
  branchId: string().nullable(),
  pin: string()
    .test('len', 'Pin must be exactly 4 characters', pin => (pin ? pin.length === 4 : true))
    .nullable(),
  license: string().nullable()
});

export default schema;
