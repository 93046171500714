import React, { useContext } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import { Context } from 'store';

import {
  Login,
  // Groups,
  Users,
  Attendance,
  Regions,
  Branches,
  Reports,
  CharacterQuestions,
  RoadQuestions,
  PreTripsQuestions,
  SkillQuestions,
  AutomatedEmails,
  DriverList,
  EmployersList,
  LmsUsage,
  CustomReports,
  Profile,
  StudentProgress,
  AdminAttendance,
  BulkYardManagement,
  ActivityLogs,
  ManageLocationType,
  ManageDuplicates,
  ForgotPassword,
  StudentRecords,
  SingleStudentRecord,
  InstructorReports,
  ClassroomScore,
  FmcsaSubmissions,
  SubmissionReport,
  EvaluationTests,
  CDLTesting,
  CDLTestingOfStudent,
  StudentGroups,
  StudentAgreements,
  Documents,
  AFPDriversReport,
  YardSchedule,
  StudentIntervention,
  UserActivityLogs
} from '_src/modules';
import Sidebar from '../Sidebar';

export default () => {
  const { getData } = useContext(Context);
  const { authenticated } = getData(['authenticated']);

  return (
    <BrowserRouter>
      <Layout>
        {authenticated && (
          <>
            <Sidebar />

            <Switch>
              <Route path="/attendance" component={Attendance} />
              {/* <Route path="/groups" component={Groups} /> */}
              <Route path="/users" component={Users} />
              <Route path="/regions" component={Regions} />
              <Route path="/records" exact component={StudentRecords} />
              <Route path="/records/:id" component={SingleStudentRecord} />
              <Route path="/characterQuestions" component={CharacterQuestions} />
              <Route path="/branches" component={Branches} />
              <Route path="/preTrips" component={PreTripsQuestions} />
              <Route path="/roadQuestions" component={RoadQuestions} />
              <Route path="/skillQuestions" component={SkillQuestions} />
              <Route path="/reports" component={Reports} />
              <Route path="/emails" component={AutomatedEmails} />
              <Route path="/drivers" component={DriverList} />
              <Route path="/employers" component={EmployersList} />
              <Route path="/lmsUsage" component={LmsUsage} />
              <Route path="/submissionReport" component={SubmissionReport} />
              <Route path="/customReports" component={CustomReports} />
              <Route path="/profile" component={Profile} />
              <Route path="/students-progress/:token" component={StudentProgress} />
              <Route path="/activity-logs" component={ActivityLogs} />
              <Route path="/bulkAttendance" component={AdminAttendance} />
              <Route path="/studentYardLocation" component={BulkYardManagement} />
              <Route path="/manage-location-type" component={ManageLocationType} />
              <Route path="/manage-duplicates" component={ManageDuplicates} />
              <Route path="/instructorReports" component={InstructorReports} />
              <Route path="/classroomScore" component={ClassroomScore} />
              <Route path="/fmcsaSubmissions" component={FmcsaSubmissions} />
              <Route path="/evaluationTests" component={EvaluationTests} />
              <Route path="/cdlTesting" component={CDLTesting} />
              <Route path="/cdlTestings/:id" component={CDLTestingOfStudent} />
              <Route path="/studentGroups" component={StudentGroups} />
              <Route path="/studentAgreements" component={StudentAgreements} />
              <Route path="/documents" component={Documents} />
              <Route path="/driversReport" component={AFPDriversReport} />
              <Route path="/yard-schedule" component={YardSchedule} />
              <Route path="/studentIntervention" component={StudentIntervention} />
              <Route path="/userActivityLogs" component={UserActivityLogs} />

              <Redirect to="/attendance" />
            </Switch>
          </>
        )}

        {!authenticated && (
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/students-progress/:token" component={StudentProgress} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Redirect to="/login" />
          </Switch>
        )}
      </Layout>
    </BrowserRouter>
  );
};
