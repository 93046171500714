import React, { useContext, useState, useEffect } from 'react';
import { Context, useSave, useDelete, useGet, useFetch } from 'store';
import moment from 'moment';
import 'moment-timezone';
import { Button, Icon, notification, Popconfirm } from 'antd';
import { PersistentRepo } from '_src/shared/repo';
import services from '_src/services';
import { formatDate, checkStudentLocation } from '_src/utils';
import { ActionRow } from '_src/shared/styles';
import { Modal, SignaturePad } from '_src/components';
import CheckoutModal from '../components/CheckoutModal';
import { Datepicker, Timepicker, Textarea, Checkbox, Select } from '_src/shared/form-helpers';

import { OverrideRow, OverrideContainer, OverrideCell, OverrideFormContainer } from '../styles';

export default ({
  student,
  selectedDateSession = moment(),
  onModalClose = () => {},
  isHazmat = false
}) => {
  const getTimezone = PersistentRepo.get('getTimezone');

  const resource = 'attendance';
  const overrideResource = 'override';
  const config = services[resource];
  const { setShown, getData, getShown, refreshResource } = useContext(Context);
  const [date, setDate] = useState(selectedDateSession);
  const [changes, setChanges] = useState({});
  const [additional, setAdditional] = useState([]);
  const [removed, setRemoved] = useState([]);
  const [absentId, setAbsent] = useState(false);
  const [errors, setError] = useState({});
  const [note, setNote] = useState();
  const [signature, setSignature] = useState();
  const [loading, setLoading] = useState(false);
  const [disableSession, setSessionState] = useState(false);
  const [disableCheckout, setCheckoutState] = useState(true);
  const [sessionDuration, setSessionDuration] = useState(false);
  const [overlappingHours, setOverlappingHours] = useState(false);
  const [notificationError, setNotificationError] = useState('');
  const [overlappError, setOverlappError] = useState('');
  const [sessions, setSessions] = useState([]);
  const [sessionToDelete, setSessionToDelete] = useState({});
  const [deleteSessionState, setDeleteSessionState] = useState(false);
  const { get: getAllSessions } = useFetch(config.override);
  const { save: updateHours, busy } = useSave({
    ...config.updateStudentHours
  });
  const userId = getData('user').id;

  const [absentStatus, setAbsentStatus] = useState(student?.excusedAbsence || false);
  var timezone = moment().utcOffset() / 60;
  const fmt = 'MM/DD/YYYY HH:mm';

  if (timezone > 0) {
    timezone = 'GMT ' + timezone;
  } else if (timezone < 0) {
    timezone = 'GMT' + timezone;
  } else if (timezone === 0) {
    timezone = 'GMT 0';
  }

  const { save: editAttendance } = useSave(config.edit);
  const { save: createAttendance } = useSave(config.create);
  const { destroy: removeAttendance } = useDelete(config.delete);
  const studentLocationId =
    getData('locations').find(({ branchId }) => branchId === student?.branchId)?.branchLocation?.[
      student?.locationType === 1 ? 'yardId' : 'classroomId'
    ] ?? student?.branchId;

  useEffect(() => {
    getAllSessions({
      params: { studentId: student?.id, date: moment.parseZone(date).format('DD-MM-YYYY') }
    }).then(allSessions => {
      setSessions(allSessions);
    });
  }, [date]);

  const closeModal = () => {
    setDate(moment());
    setShown(overrideResource, false);
    setShown('checkoutOverride', false);
    setDeleteSessionState(false);
    setRemoved([]);
  };

  // check sessionDuration onchange of attendance
  useEffect(() => {
    let requests = Object.entries(changes)
      .filter(([_, data]) => filterChanges(data))
      .map(([id, data]) => {
        const checkInInitial = sessions.find(x => x.id == id).checkIn;
        const checkOutInitial = sessions.find(x => x.id == id).checkOut;
        const dateCheckIn = new Date(checkInInitial);
        var checkInChanged = '';
        var checkOutChanged = '';

        if (data.checkIn) {
          checkInChanged = new Date(
            checkInInitial.year(),
            checkInInitial.month(),
            checkInInitial.date(),
            data.checkIn.hours(),
            data.checkIn.minutes()
          );
        }

        if (data.checkOut) {
          var checkInCheck = data.checkIn ? data.checkIn.hours() : checkInInitial.hours();

          if (data.checkOut.hours() < checkInCheck) {
            var todayDate = moment(checkInInitial);
            todayDate.add(1, 'days');
            checkOutChanged = new Date(
              todayDate.year(),
              todayDate.month(),
              todayDate.date(),
              data.checkOut.hours(),
              data.checkOut.minutes()
            );
          } else {
            checkOutChanged = new Date(
              checkInInitial.year(),
              checkInInitial.month(),
              checkInInitial.date(),
              data.checkOut.hours(),
              data.checkOut.minutes()
            );
          }
        } else if (checkOutInitial && data.checkIn) {
          let checkInCheck = data.checkIn ? data.checkIn : checkInInitial;
          if (checkOutInitial.hours() < checkInCheck.hours()) {
            var todayDate = moment(checkInInitial);
            todayDate.add(1, 'days');
            checkOutChanged = new Date(
              todayDate.year(),
              todayDate.month(),
              todayDate.date(),
              checkOutInitial.hours(),
              checkOutInitial.minutes()
            );
          } else {
            checkOutChanged = new Date(
              checkInInitial.year(),
              checkInInitial.month(),
              checkInInitial.date(),
              checkOutInitial.hours(),
              checkOutInitial.minutes()
            );
          }
        }

        if (checkInChanged || checkOutChanged) {
          const checkIn = checkInChanged
            ? moment.parseZone(checkInChanged).format(fmt)
            : moment.parseZone(checkInInitial).format(fmt);
          const checkOut = checkOutChanged
            ? moment.parseZone(checkOutChanged).format(fmt)
            : moment.parseZone(checkOutInitial).format(fmt);

          var duration = moment(checkOut)
            .set('seconds', 0)
            .diff(moment(checkIn).set('seconds', 0), 'minutes');

          if (isHazmat && duration !== 240) {
            setSessionDuration(false);
            setSessionState(true);
            setNotificationError('Session duration can not be less than 4 hours or more');
            notification.error({
              message: 'Session duration can not be less than 4 hours or more',
              duration: 5
            });
          } else if (!isHazmat && (duration < 20 || duration > 600)) {
            setSessionDuration(false);
            setSessionState(true);
            setNotificationError(
              'Session duration can not be less than 20 minutes or more than 10 hours'
            );
            notification.error({
              message: 'Session duration can not be less than 20 minutes or more than 10 hours',
              duration: 5
            });
          } else if (!checkOutChanged) {
            setSessionDuration(true);
            setNotificationError('');
          } else {
            setSessionDuration(true);
            setSessionState(false);
            setNotificationError('');
          }
        }
      });
  }, [changes]);

  const checkDuration = (checkInInput, checkOutInput) => {
    const dateCheckIn = new Date(checkInInput);
    let checkInChanged = '';
    let checkOutChanged = '';

    if (checkInInput) {
      checkInChanged = new Date(
        dateCheckIn.getFullYear(),
        dateCheckIn.getMonth(),
        dateCheckIn.getDate(),
        new Date(checkInInput).getHours(),
        new Date(checkInInput).getMinutes()
      );
    }

    if (checkOutInput) {
      if (new Date(checkOutInput).getHours() < new Date(checkInInput).getHours()) {
        var todayDate = moment(checkInInput).add(1, 'days');
        checkOutChanged = new Date(
          new Date(todayDate).getFullYear(),
          new Date(todayDate).getMonth(),
          new Date(todayDate).getDate(),
          new Date(checkOutInput).getHours(),
          new Date(checkOutInput).getMinutes()
        );
      } else {
        checkOutChanged = new Date(
          dateCheckIn.getFullYear(),
          dateCheckIn.getMonth(),
          dateCheckIn.getDate(),
          new Date(checkOutInput).getHours(),
          new Date(checkOutInput).getMinutes()
        );
      }
    }

    if (checkInChanged || checkOutChanged) {
      let utcTimeCheckin = moment.parseZone(checkInInput).format(fmt);
      let utcTimeCheckout = moment.parseZone(checkOutInput).format(fmt);

      const checkIn = checkInChanged ? checkInChanged : moment.tz(utcTimeCheckin, fmt, getTimezone);
      const checkOut = checkOutChanged
        ? checkOutChanged
        : moment.tz(utcTimeCheckout, fmt, getTimezone);

      var duration = moment(checkOut)
        .set('seconds', 0)
        .diff(moment(checkIn).set('seconds', 0), 'minutes');

      if (isHazmat && duration !== 240) {
        setSessionDuration(false);

        setNotificationError('Session duration can not be less than 4 hours or more');
        notification.error({
          message: 'Session duration can not be less than 4 hours or more',
          duration: 5
        });
        return false;
      } else if (!isHazmat && (duration < 20 || duration > 600)) {
        setSessionDuration(false);

        setNotificationError(
          'Session duration can not be less than 20 minutes or more than 10 hours'
        );
        notification.error({
          message: 'Session duration can not be less than 20 minutes or more than 10 hours',
          duration: 5
        });
        return false;
      } else if (!checkOutChanged) {
        setSessionDuration(true);
        setNotificationError('');
        return true;
      } else {
        setSessionDuration(true);
        setNotificationError('');
        return true;
      }
    }
  };

  const checkOverlappingCheckin = (value, index) => {
    let currentSessionId = sessions[index].id;
    let pastTime;
    if (changes[currentSessionId] && changes[currentSessionId].checkOut) {
      pastTime = changes[currentSessionId].checkOut;
    } else {
      pastTime = sessions[index].checkOut;
    }
    let current = moment.tz(moment.parseZone(value).format(fmt), fmt, getTimezone);
    let past = moment.tz(moment.parseZone(pastTime).format(fmt), fmt, getTimezone);
    if (current <= past) {
      setOverlappError('Session should start after checkout of previous session');
      notification.error({
        message: 'Session should start after checkout of previous session',
        duration: 5
      });
      return true;
    } else {
      setOverlappError('');
      return false;
    }
  };

  const checkOverlappingNewCheckin = (value, index) => {
    let current = moment.tz(moment.parseZone(value).format(fmt), fmt, getTimezone);
    let past = moment.tz(
      moment.parseZone(additional[index].checkOut).format(fmt),
      fmt,
      getTimezone
    );
    if (current <= past) {
      setOverlappError('Session should start after checkout of previous session');
      notification.error({
        message: 'Session should start after checkout of previous session',
        duration: 5
      });
      return true;
    } else {
      setOverlappError('');
      return false;
    }
  };

  const checkOverlappingCheckout = (value, index) => {
    let currentSessionId = sessions[index].id;
    let pastTime;
    if (changes[currentSessionId] && changes[currentSessionId].checkIn) {
      pastTime = changes[currentSessionId].checkIn;
    } else {
      pastTime = sessions[index].checkIn;
    }
    let current = moment.tz(moment.parseZone(value).format(fmt), fmt, getTimezone);
    let past = moment.tz(moment.parseZone(pastTime).format(fmt), fmt, getTimezone);
    if (current >= past) {
      setOverlappError('Session should finish before next session!');
      notification.error({
        message: 'Session should finish before next session!',
        duration: 5
      });
      return true;
    } else {
      setOverlappError('');
      return false;
    }
  };

  useEffect(() => {
    const absentSession = sessions
      .filter(({ id }) => !removed.includes(id))
      .find(({ isAbsent }) => isAbsent);

    if (absentId !== -1) {
      setAbsent(absentSession ? absentSession.id : false);
      setAbsentStatus(!!absentSession?.excusedAbsence || false);
    }

    if (!absentSession) {
      setSessionState(false);
    }
    const hasCheck = sessions.some(({ checkOut }, index) => {
      if (index === sessions.length - 1 && checkOut == null) {
        setSessionState(true);
      }
    });
  }, [sessions.map(({ id }) => id).join(',')]);

  const onOk = continueEditing => {
    if (!note || !signature) {
      setError({ ...errors, note: !note, signature: !signature });
      return;
    }
    setLoading(true);

    let requests = Object.entries(changes)
      .filter(([_, data]) => filterChanges(data))
      .map(([id, data]) => {
        let dataToPost = {
          notes: note,
          instructorSignature: signature,
          manual: true,
          ...(isHazmat && { isHazmat: true })
        };

        if (data.checkIn) {
          if (data.duration) {
            dataToPost.checkIn = moment.utc(data.checkIn).unix();
          } else {
            dataToPost.checkIn = data.checkIn.unix();
          }
        }

        if (data.checkOut) {
          if (data.duration) {
            dataToPost.checkOut = moment.utc(data.checkOut).unix();
          } else {
            dataToPost.checkOut = data.checkOut.unix();
          }
        }

        if (data?.locationType) {
          dataToPost.locationType = data.locationType;
        }
        if (sessionDuration && !overlappingHours) {
          return editAttendance({ id, ...dataToPost });
        }
      });

    const fmt = 'MM/DD/YYYY HH:mm';
    let utcTime = moment.parseZone(moment(date)).format(fmt);
    let nowEdit = moment.tz(utcTime, fmt, getTimezone).unix();

    const data = {
      studentId: student?.id,
      locationId: studentLocationId,
      locationType: student?.locationType,
      branchId: student?.branchId,
      attendanceDate: nowEdit,
      notes: note,
      instructorSignature: signature,
      manual: true,
      ...(isHazmat && { isHazmat: true })
    };

    removed.forEach(id => {
      requests.push(
        removeAttendance({
          id,
          notes: note,
          instructorSignature: signature,
          manual: true,
          ...(isHazmat && { isHazmat: true })
        })
      );
    });

    additional.forEach(({ checkIn, checkOut, locationType }) => {
      if (checkIn) {
        checkIn.date(date.date());
        checkIn.month(date.month());
        checkIn.year(date.year());

        if (checkOut) {
          checkOut.date(date.date());
          checkOut.month(date.month());
          checkOut.year(date.year());
          if (moment(checkIn).valueOf() > moment(checkOut).valueOf()) {
            checkOut.add(1, 'days');
          }
        }
        if (sessionDuration && !overlappingHours && !deleteSessionState) {
          requests.push(
            createAttendance({
              ...data,
              checkIn: checkIn.unix(),
              checkOut: checkOut ? checkOut.unix() : checkOut,
              notes: note,
              instructorSignature: signature,
              locationType,
              manual: true,
              ...(isHazmat && { isHazmat: true })
            })
          );
        }
      }
    });

    if (absentId === -1 && !deleteSessionState) {
      requests.push(createAttendance({ ...data, excusedAbsence: absentStatus }));
    } else if (absentId && !deleteSessionState) {
      requests.push(editAttendance({ id: absentId, excusedAbsence: absentStatus }));
    }

    if (
      (requests.length > 0 || removed.length > 0 || absentId) &&
      sessionDuration &&
      !overlappingHours
    ) {
      Promise.all(requests).then(allRes => {
        const isValid = allRes.every(({ success }) => success === 1);

        if (isValid) {
          getAllSessions({
            params: { studentId: student?.id, date: moment.parseZone(date).format('DD-MM-YYYY') }
          }).then(allSessionsRes => {
            setLoading(false);
            let sessionsTotal = 0;
            allSessionsRes
              .filter(({ locationType }) => locationType === 2)
              .map(({ checkIn, checkOut }) => {
                if (checkIn && checkOut) {
                  sessionsTotal += moment
                    .duration(moment(checkOut).diff(moment(checkIn)))
                    .asHours();
                }
              });

            // class hours breakdown if student has classroom hours for the day
            if (sessionsTotal !== 0) {
              const values = {
                classHours: +sessionsTotal.toFixed(1),
                studentId: student?.id,
                userId,
                hoursBreakdownDate: moment.parseZone(date).format('YYYY-MM-DD')
              };
              updateHours(values).then(({ success }) => {});
            }
            setTimeout(() => {
              if (
                student?.locationType === 1 &&
                allSessionsRes.length > 0 &&
                allSessionsRes[0].checkOut &&
                continueEditing &&
                !deleteSessionState
              ) {
                setShown('checkoutModal');
              } else if (!deleteSessionState) {
                closeModal();
                onModalClose(selectedDateSession);
              }

              refreshResource(resource);
              notification.success({
                message: `Successfully updated attendance information`,
                duration: 3
              });
            }, 0);
          });
        } else {
          console.log('not valid');
        }
      });
    } else if (removed.length > 0) {
      setDeleteSessionState(false);
      setRemoved([]);
      refreshResource(resource);
      !deleteSessionState && closeModal();
      onModalClose(selectedDateSession);

      notification.success({
        message: `Successfully updated attendance information`,
        duration: 3
      });
      setLoading(false);
    } else if (!absentId || !sessionDuration || overlappingHours) {
      notification.warning({
        message: notificationError
          ? notificationError
          : overlappError
          ? overlappError
          : 'No changes were made',
        duration: 3
      });
      setLoading(false);
    } else {
      !deleteSessionState && closeModal();
    }
  };

  const filterChanges = ({
    checkIn,
    checkOut,
    checkInInitial,
    checkOutInitial,
    locationType,
    locationTypeInitial
  }) => {
    checkInInitial = formatDate(checkInInitial, 'HH:mm');
    checkOutInitial = formatDate(checkOutInitial, 'HH:mm');
    checkIn = formatDate(checkIn, 'HH:mm');
    checkOut = formatDate(checkOut, 'HH:mm');

    const hasCheckinChanged = checkIn !== checkInInitial;
    const hasCheckoutChanged = checkOut !== checkOutInitial;
    const hasLocationTypeChanged = locationType !== locationTypeInitial;

    return hasCheckinChanged || hasCheckoutChanged || hasLocationTypeChanged;
  };

  const getTime = (attendance, type) => {
    const { id } = attendance;
    const initial = attendance[type];
    const change = changes[id];

    if (change && change.hasOwnProperty(type)) {
      return change[type];
    }

    return initial;
  };

  const setTime = (attendance, value, type) => {
    let utcTime = moment.parseZone(value).format(fmt);
    value = moment.tz(utcTime, fmt, getTimezone);

    if (value == null) {
      return;
    }
    const { id } = attendance;
    const initial = attendance[type];
    let currentChanges = changes[id] || {};

    // handle +/- 1 date for checkout in case of night hours
    // if (type === 'checkIn' && attendance.checkOut) {
    //   let currentCheckin = currentChanges && currentChanges.checkIn ? currentChanges.checkIn : attendance.checkIn
    //   let newCheckout = currentChanges && currentChanges.checkOut ? currentChanges.checkOut : attendance.checkOut

    //   if (moment(currentCheckin).hours() < 9) {
    //     newCheckout.date(date.date())
    //     newCheckout.month(date.month())
    //     newCheckout.year(date.year())

    //     let newCheckoutValue = { ['checkOut']: moment.tz(moment.parseZone(newCheckout).format(fmt), fmt, getTimezone), [`checkOutInitial`]: moment.parseZone(attendance.checkOut) };
    //     currentChanges = { ...currentChanges, ...newCheckoutValue }
    //   }

    //   if (moment(newCheckout).valueOf() < moment(currentCheckin).valueOf()) {
    //     newCheckout.add(1, 'days')

    //     let newCheckoutValue = { ['checkOut']: moment.tz(moment.parseZone(newCheckout).format(fmt), fmt, getTimezone), [`checkOutInitial`]: moment.parseZone(attendance.checkOut) };
    //     currentChanges = { ...currentChanges, ...newCheckoutValue }
    //   }
    // }

    if (type === 'checkIn') {
      if (!changes.checkOut) {
        const parsedCheckoutTime = moment.parseZone(moment(attendance.checkOut)).format(fmt);
        const parseCheckoutTimeValue = moment.tz(parsedCheckoutTime, fmt, getTimezone);

        const checkoutInPast = value.diff(parseCheckoutTimeValue, 'hours') < -24;
        const checkoutShouldBeInFuture = moment(value).isAfter(parseCheckoutTimeValue);

        if (checkoutInPast) {
          const checkoutTimeToMoment = parseCheckoutTimeValue.subtract(1, 'days');

          currentChanges = {
            ...currentChanges,
            checkOut: checkoutTimeToMoment
          };
        }

        if (checkoutShouldBeInFuture) {
          const checkoutTimeToMoment = parseCheckoutTimeValue.add(1, 'days');

          currentChanges = {
            ...currentChanges,
            checkOut: checkoutTimeToMoment
          };
        }
      }
    }

    if (type === 'checkOut') {
      let pastTime;
      if (changes[id] && changes[id].checkIn) {
        pastTime = changes[id].checkIn;
      } else {
        pastTime = attendance.checkIn;
      }
      value.date(date.date());
      value.month(date.month());
      value.year(date.year());
      if (moment(value).valueOf() < moment(pastTime).valueOf()) {
        value.add(1, 'days');
      }
    }

    let newValue = { ...currentChanges, [type]: value, [`${type}Initial`]: initial };
    let currentSessionIndex = sessions.findIndex(({ id }) => id === attendance.id);

    if (type === 'checkIn') {
      if (currentSessionIndex !== 0) {
        setOverlappingHours(checkOverlappingCheckin(value, currentSessionIndex - 1));
      }
    }

    if (type === 'checkOut') {
      if (currentSessionIndex !== sessions.length - 1) {
        setOverlappingHours(checkOverlappingCheckout(value, currentSessionIndex + 1));
      }
    }

    setChanges({ ...changes, [id]: newValue });
  };

  const setNewTime = (index, value, type) => {
    value = moment.tz(value, fmt, getTimezone);

    if (value == null) {
      return;
    }
    const newAdditional = [...additional];
    const current = { ...newAdditional[index] };

    if (type === 'checkOut') {
      // const checkInTime = current.checkIn;
      // value = value.isBefore(checkInTime) ? checkInTime : value;
    }

    if (type === 'checkIn') {
      if (sessions.length !== 0) {
        setOverlappingHours(checkOverlappingCheckin(value, sessions.length - 1));
      } else if (additional.length > 1) {
        setOverlappingHours(checkOverlappingNewCheckin(value, additional.length - 2));
      }
      setCheckoutState(false);
    }

    current[type] = value;
    newAdditional[index] = current;

    if (current.checkIn && !current.checkOut) {
      setSessionDuration(true);
      setSessionState(true);
    } else if (current.checkIn && current.checkOut) {
      const durationRes = checkDuration(current.checkIn, current.checkOut);
      setSessionDuration(durationRes);
      setSessionState(!durationRes);
    }
    setAdditional(newAdditional);
  };

  const removeAdditional = index => {
    const newAdditional = [...additional];
    newAdditional.splice(index, 1);
    setAdditional(newAdditional);
    setSessionState(false);
    setSessionDuration(true);
    setNotificationError('');
    setOverlappingHours(false);
    setOverlappError('');
  };

  const removeSession = id => {
    const index = sessions.findIndex(session => session.id === id);
    const newSessions = [...sessions];
    newSessions.splice(index, 1);
    setSessions([...newSessions]);
    setRemoved([...removed, id]);
    setSessionState(false);
    setSessionDuration(true);
    setNotificationError('');
    setOverlappingHours(false);
    setOverlappError('');
  };

  const markAsAbsent = () => {
    if (!absentId) {
      setDeleteSessionState(false);
      const newRemoved = sessions.map(attendance => attendance.id);
      setRemoved([...removed, ...newRemoved]);
      setAdditional([]);
      setAbsent(-1);
      setAbsentStatus(false);
      setSessionDuration(true);
      setNotificationError('');
      setOverlappError('');
    }
  };

  const addSession = () => {
    sessions
      .filter(({ checkIn, checkOut }) => !checkIn && !checkOut)
      .forEach(({ id }) => {
        setSessionState(false);
      });
    setAdditional([...additional, { locationType: student?.locationType }]);
    setAbsent(false);
    setAbsentStatus(false);
    setSessionState(true);
  };

  const setLocationType = (attendance, locationType) => {
    const { id } = attendance;
    const currentChanges = changes[id] || {};

    setChanges({
      ...changes,
      [id]: { ...currentChanges, locationType, locationTypeInitial: attendance?.locationType }
    });
    setSessionDuration(true);
  };

  const setNewLocationType = (index, locationType) => {
    const newAdditional = [...additional];
    const current = additional[index];
    current.locationType = locationType;
    newAdditional[index] = current;

    setAdditional(newAdditional);
  };

  const getLocationType = attendance => {
    const { id } = attendance;
    const { locationType } = changes[id] || {};

    return locationType || attendance?.locationType;
  };

  const renderButtons = () => {
    return (
      <>
        <Button
          key="submitClose"
          type="primary"
          loading={loading}
          onClick={() => onOk(false)}
          disabled={loading}
        >
          Save and close
        </Button>
        {student?.locationType == 1 && (
          <Button
            key="submitEdit"
            type="primary"
            loading={loading}
            onClick={() => {
              onOk(true);
            }}
            disabled={loading}
          >
            Save and edit
          </Button>
        )}
      </>
    );
  };

  const renderDeleteSession = attendance => {
    const { hoursTrained, totalClassHours, locationType } = attendance;
    const classHoursRemained = totalClassHours - hoursTrained < 40;
    const showPopconfirm = classHoursRemained && locationType === 2;

    const handleDeleteClick = () => continueDeleteSession(attendance);

    return showPopconfirm ? (
      <Popconfirm
        title={
          <>
            Deleting this session will reduce the total class hours to below 40.
            <br />
            Are you sure you want to proceed?
          </>
        }
        okText="Delete"
        onConfirm={handleDeleteClick}
      >
        <Icon type="close" onClick={() => {}} />
      </Popconfirm>
    ) : (
      <Icon type="close" onClick={handleDeleteClick} />
    );
  };

  const continueDeleteSession = attendance => {
    if (sessions.some(({ id }) => id === attendance.id)) {
      setDeleteSessionState(true);
      setSessionToDelete(attendance);
      setShown('deleteSession');
    }
    removeSession(attendance.id);
    setSessionState(false);
  };

  const renderSessions = () => {
    return (
      <OverrideContainer>
        <OverrideRow columns={!!absentId ? '4' : '3'}>
          <OverrideCell>Checkin</OverrideCell>
          <OverrideCell>Checkout</OverrideCell>
          {!!absentId && (
            <OverrideCell>{absentStatus ? 'Absent Excused' : 'Not Scheduled'}</OverrideCell>
          )}
          <OverrideCell>Location</OverrideCell>
          <OverrideCell />
        </OverrideRow>

        {sessions
          .filter(({ id, isAbsent }) => !removed.includes(id) && !isAbsent)
          .map(attendance => (
            <OverrideRow key={attendance.id}>
              <OverrideCell>
                <Timepicker
                  allowClear={false}
                  format="HH:mm"
                  value={moment
                    .parseZone(getTime(attendance, 'checkIn'))
                    .format('MM/DD/YYYY HH:mm')}
                  onChange={time => {
                    setTime(attendance, time ? time : moment.parseZone(moment(date)), 'checkIn');
                  }}
                />
              </OverrideCell>

              <OverrideCell>
                <Timepicker
                  allowClear={false}
                  format="HH:mm"
                  value={
                    attendance.checkOut
                      ? moment.parseZone(getTime(attendance, 'checkOut')).format('MM/DD/YYYY HH:mm')
                      : getTime(attendance, 'checkOut')
                  }
                  onChange={time => {
                    setTime(
                      attendance,
                      time ? time.month(date.month()) : moment.parseZone(moment(date)),
                      'checkOut'
                    );
                    // setSessionState(false);
                  }}
                />
              </OverrideCell>

              <OverrideCell>
                <Select
                  value={getLocationType(attendance)}
                  options={[
                    { id: 2, label: 'Classroom' },
                    { id: 1, label: 'Yard' }
                  ]}
                  onChange={locationType => setLocationType(attendance, locationType)}
                  disabled={isHazmat}
                />
              </OverrideCell>

              <OverrideCell>{renderDeleteSession(attendance)}</OverrideCell>
            </OverrideRow>
          ))}

        {additional.map(({ checkIn, checkOut, locationType }, index) => (
          <OverrideRow key={index}>
            <OverrideCell>
              <Timepicker
                allowClear={false}
                format="HH:mm"
                value={checkIn}
                onOpenChange={val => {
                  if (!checkIn) {
                    setNewTime(
                      index,
                      moment(date).tz(moment.tz.guess()).format('MM/DD/YYYY HH:mm'),
                      'checkIn',
                      true
                    );
                  }
                }}
                onChange={time => {
                  setNewTime(
                    index,
                    time ? time : moment.parseZone(moment(date)).format(fmt),
                    'checkIn',
                    false
                  );
                }}
              />
            </OverrideCell>

            <OverrideCell>
              <Timepicker
                allowClear={false}
                format="HH:mm"
                value={checkOut}
                onOpenChange={val => {
                  if (!checkOut) {
                    if (!checkOut) {
                      setNewTime(
                        index,
                        moment(checkIn)
                          .tz(moment.tz.guess(), true)
                          .add(4, 'hours')
                          .format('MM/DD/YYYY HH:mm'),
                        'checkOut',
                        true
                      );
                    }
                  }
                }}
                onChange={time => {
                  setNewTime(
                    index,
                    time ? time : moment.parseZone(moment(date)).format(fmt),
                    'checkOut',
                    false
                  );
                  // setSessionState(false);
                }}
                disabled={disableCheckout}
              />
            </OverrideCell>

            <OverrideCell>
              <Select
                value={locationType}
                options={[
                  { id: 2, label: 'Classroom' },
                  { id: 1, label: 'Yard' }
                ]}
                onChange={locationType => setNewLocationType(index, locationType)}
                disabled={isHazmat}
              />
            </OverrideCell>

            <OverrideCell>
              <Icon
                type="close"
                onClick={() => {
                  setDeleteSessionState(true);
                  removeAdditional(index);
                  setSessionState(false);
                }}
              />
            </OverrideCell>
          </OverrideRow>
        ))}

        {absentId && (
          <OverrideRow columns="4">
            <OverrideCell>Absent</OverrideCell>
            <OverrideCell>Absent</OverrideCell>
            <OverrideCell>
              <Checkbox checked={absentStatus} onChange={setAbsentStatus} />
            </OverrideCell>

            <OverrideCell>{student?.locationType == 1 ? 'Yard' : 'Classroom'}</OverrideCell>

            <OverrideCell onClick={e => e.stopPropagation()}>
              <Icon
                type="close"
                onClick={() => {
                  if (absentId !== -1) {
                    setRemoved([...removed, absentId]);
                  }
                  setDeleteSessionState(true);
                  setSessionToDelete(absentId);
                  setShown('deleteSession');
                  setSessionState(false);
                }}
              />
            </OverrideCell>
          </OverrideRow>
        )}
      </OverrideContainer>
    );
  };

  const deleteSession = () => {
    const values = {
      studentId: student?.id,
      userId,
      hoursBreakdownDate: moment.parseZone(date).format('YYYY-MM-DD'),
      action: 'delete'
    };

    if (!note || !signature) {
      setError({ ...errors, note: !note, signature: !signature });
      return;
    }

    updateHours(values).then(({ success }) => {
      if (success === 1) {
        setAbsent(false);
        onOk(true);

        notification.success({
          message: `Session successfully delete`,
          duration: 3
        });
        setShown('deleteSession', false);
      } else {
        notification.error({
          message: `Unable to delete session`,
          duration: 3
        });
      }
    });
  };

  return (
    <>
      <Modal
        title="Override Attendance"
        visible={getShown('override') || getShown('checkoutOverride')}
        onClose={closeModal}
        width="60%"
        footer={[
          <Button key="back" onClick={closeModal}>
            Cancel
          </Button>,
          renderButtons()
        ]}
      >
        <OverrideFormContainer>
          <Datepicker
            allowClear={false}
            label="Select a date"
            name="overrideDate"
            value={date}
            onChange={date => {
              setAdditional([]);
              setRemoved([]);
              setAbsent(false);
              setAbsentStatus(false);
              setDate(date);
              setChanges({});
              setSessionState(false);
            }}
          />

          <>
            <ActionRow>
              {!isHazmat && (
                <Button type="primary" onClick={markAsAbsent}>
                  Mark As Absent
                </Button>
              )}
              <Button
                type="primary"
                onClick={addSession}
                disabled={disableSession || overlappingHours}
              >
                Add session
              </Button>
            </ActionRow>

            {renderSessions()}
            {!isHazmat && (
              <Button
                type="primary"
                style={{ margin: '10px 0' }}
                onClick={() => setShown('checkoutModal')}
                disabled={
                  sessions.length === 0 ||
                  (sessions.length === 1 && !sessions[0].checkOut) ||
                  isHazmat
                }
              >
                Hours Breakdown
              </Button>
            )}
          </>

          <Textarea
            value={note}
            onChange={note => {
              setNote(note);
              setError({ ...errors, note: !note });
            }}
            touched
            error={errors.note ? 'Note is required' : ''}
            label="Notes"
            rows={3}
          />

          <SignaturePad
            label="Signature"
            error={errors.signature ? 'Signature is required' : ''}
            onChange={signature => {
              setSignature(signature);
              setError({ ...errors, signature: !signature });
            }}
          />
        </OverrideFormContainer>
      </Modal>

      <Modal
        title="Delete Session"
        visible={getShown('deleteSession')}
        onClose={closeModal}
        width="60%"
        footer={[
          <Button
            key="back"
            onClick={() => {
              if (!absentId) {
                setSessions([...sessions, sessionToDelete]);
              }
              setRemoved(removed.filter(id => id !== sessionToDelete.id));
              setSessionState(true);
              setShown('deleteSession', false);
            }}
          >
            Cancel
          </Button>,
          <Button key="delete" onClick={deleteSession}>
            Delete
          </Button>
        ]}
      >
        <Textarea
          value={note}
          onChange={note => {
            setNote(note);
            setError({ ...errors, note: !note });
          }}
          touched
          error={errors.note ? 'Note is required' : ''}
          label="Notes"
          rows={3}
        />

        <SignaturePad
          label="Signature"
          error={errors.signature ? 'Signature is required' : ''}
          onChange={signature => {
            setSignature(signature);
            setError({ ...errors, signature: !signature });
          }}
        />
      </Modal>

      {getShown('checkoutModal') && (
        <CheckoutModal
          student={student}
          checkoutTime={date.format('DD-MM-YYYY')}
          onClose={() => {
            setShown('checkoutModal', false);
            closeModal();
          }}
        />
      )}
    </>
  );
};
