import React, { useState } from 'react';
import { Modal, Button, Icon, Checkbox, Radio } from 'antd';

import services from '_src/services';
import {
  FilterModalContainer,
  FilterModalHeader,
  FilterModalTitle,
  FilterWrapper,
  FilterTitle
} from '../styles';

const studentOptions = [
  { label: 'Yard Students', value: 1 },
  { label: 'Classroom Students', value: 2 }
];

const statusOptions = [
  { label: 'Enrolled', value: 1 },
  { label: 'Passed CDL Exam', value: 2 },
  { label: 'Graduated', value: 3 }
];

function FilterModal(props) {
  const { visible, onFilter, onClose } = props;

  const [studentTypes, setStudentTypes] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [hasPermit, setHasPermit] = useState(null);

  const config = services['studentRecords'];

  return (
    <Modal
      style={{ minWidth: 580 }}
      visible={visible}
      onCancel={onClose}
      title={null}
      footer={null}
    >
      <FilterModalContainer>
        <FilterModalHeader>
          <div>
            <FilterModalTitle>Filter Results</FilterModalTitle>
            <p>For Student Records</p>
          </div>
          <Icon type="close" style={{ fontSize: 18 }} onClick={onClose} />
        </FilterModalHeader>
        <FilterWrapper>
          <FilterTitle>Students:</FilterTitle>
          <Checkbox.Group
            value={studentTypes}
            options={studentOptions}
            onChange={checkedTypes => {
              setStudentTypes(checkedTypes);
            }}
          />
        </FilterWrapper>
        <FilterWrapper>
          <FilterTitle>Status:</FilterTitle>
          <Radio.Group
            style={{ marginBottom: 16 }}
            value={hasPermit}
            onChange={e => {
              setHasPermit(e.target.value);
            }}
          >
            <Radio value={1}>Has a Permit</Radio>
            <Radio value={0}>No Permit</Radio>
          </Radio.Group>
          <Checkbox.Group
            value={statuses}
            options={statusOptions}
            onChange={checkedStatuses => {
              setStatuses(checkedStatuses);
            }}
          />
        </FilterWrapper>
        <Button
          type="primary"
          style={{
            backgroundColor: '#E55F17',
            borderColor: '#E55F17',
            marginTop: 32,
            marginBottom: 10,
            width: '100%'
          }}
          size="large"
          onClick={() => {
            onFilter({
              studentTypes: studentTypes.length > 1 ? 0 : studentTypes[0],
              statuses,
              hasPermit
            });
            onClose();
          }}
        >
          Filter results
        </Button>
      </FilterModalContainer>
    </Modal>
  );
}

export default FilterModal;
