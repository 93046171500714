import React from 'react';
import { Tag, Tooltip } from 'antd';
import { reportTypes, reportTypesDescriptions } from '_src/constants';

const frequencyMap = {
  1: 'Daily noon',
  2: 'Daily morning',
  3: 'Every monday morning',
  4: 'Every wednesday at noon',
  5: 'Every friday at noon'
};

const reportMap = reportTypes.reduce((acc, item) => {
  acc[item.id] = item.label;
  return acc;
}, {});

const reportDescription = reportTypesDescriptions.reduce((acc, item) => {
  acc[item.id] = item.description;
  return acc;
}, {});

export default [
  {
    title: 'Title',
    dataIndex: 'name',
    render: name => {
      return (
        <Tooltip title={name}>
          <span
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'block',
              maxWidth: '300px'
            }}
          >
            {name}
          </span>
        </Tooltip>
      );
    },
    sorter: (a, b) => a.name.localeCompare(b.name)
  },
  {
    title: 'Frequency',
    dataIndex: 'frequency',
    sorter: (a, b) => a.frequency - b.frequency,
    render: frequency => frequencyMap[frequency]
  },
  {
    key: 'reportType',
    title: 'Report',
    dataIndex: 'type',
    sorter: (a, b) => a.type - b.type,
    render: type => reportMap[type]
  },
  {
    key: 'description',
    title: 'Description',
    dataIndex: 'type',
    render: type => {
      const text = reportDescription[type] || 'Unknown Report';
      return (
        <Tooltip title={text}>
          <span
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'block',
              maxWidth: '300px'
            }}
          >
            {text}
          </span>
        </Tooltip>
      );
    }
  },
  {
    title: 'Sponsors',
    dataIndex: 'sponsors',
    render: sponsors => (
      <div>
        <div>{sponsors.slice(0, 2).join(', ')}</div>
        {sponsors.length > 2 && (
          <Tooltip
            placement="bottom"
            title={
              <div style={{ padding: '10px' }}>
                {sponsors.map(sponsor => (
                  <Tag color="blue" key={sponsor} style={{ marginBottom: '5px', display: 'block' }}>
                    {sponsor}
                  </Tag>
                ))}
              </div>
            }
          >
            <span style={{ cursor: 'pointer', color: '#1890ff' }}>+{sponsors.length - 2} more</span>
          </Tooltip>
        )}
      </div>
    )
  },
  {
    title: 'Recipients',
    dataIndex: 'emails',
    render: emails => (
      <>
        <div>
          <Tag color="blue">{emails[0]}</Tag>
          {emails.length > 1 && (
            <Tooltip
              placement="bottom"
              title={
                <div style={{ padding: '10px 5px' }}>
                  {emails.map(email => (
                    <Tag
                      color="blue"
                      key={email}
                      style={{ marginBottom: '5px', display: 'block', minWidth: '100%' }}
                    >
                      {email}
                    </Tag>
                  ))}
                </div>
              }
            >
              <span style={{ cursor: 'pointer', color: '#1890ff' }}>+{emails.length - 1} more</span>
            </Tooltip>
          )}
        </div>
      </>
    )
  }
];
