import moment from 'moment';
import { stripNullObj, dateToUnix, isUrl } from '_src/utils';
import { PersistentRepo } from '_src/shared/repo';
import 'moment-timezone';

const transformAll = ({ data: { students, total } }) => {
  const token = PersistentRepo.get('token');

  const data = (students || []).map(row => {
    const {
      profilePicture,
      weekendClass,
      nightClass,
      attendance,
      totalHoursTrainedYard,
      totalHoursTrainedClassroom,
      pretripsScore,
      skillsScore,
      roadsScore,
      automaticGear,
      archived,
      birthdate,
      dateClassBegins,
      permitExpiration,
      permitRecDate
    } = row;

    return {
      ...row,
      birthdate: birthdate ? moment(birthdate).parseZone() : null,
      dateClassBegins: dateClassBegins ? moment(dateClassBegins).parseZone() : null,
      permitExpiration: permitExpiration ? moment(permitExpiration).parseZone() : null,
      archived: archived ? 'Yes' : 'No',
      totalHoursTrained: Math.round((totalHoursTrainedYard + totalHoursTrainedClassroom) * 10) / 10,
      totalHoursTrainedYard: Math.round(totalHoursTrainedYard * 10) / 10,
      totalHoursTrainedClassroom: Math.round(totalHoursTrainedClassroom * 10) / 10,
      attendance: attendance ? 'Y' : 'N',
      classType: weekendClass ? 'Weekend Class' : nightClass ? 'Night Class' : 'Normal Class',
      profilePicture: profilePicture
        ? `${GLOBALS.BASE_API}/students/profile_picture/${profilePicture}/${token}`
        : profilePicture,
      pretripsScore: `${(pretripsScore * 100).toFixed(0)}%`,
      skillsScore: `${(skillsScore * 100).toFixed(0)}%`,
      roadsScore: `${(roadsScore * 100).toFixed(0)}%`,
      automaticGear: automaticGear ? true : false,
      permitRecDate
    };
  });

  return { data, total };
};

const transformStudentAttendance = ({ data }) => {
  let timezone = data.timezone;
  let attendance = (data?.attendance || [])
    .filter(({ archivedDate }) => !archivedDate)
    .map(({ id, attendanceDate, checkIn, checkOut, locationType, excusedAbsence = null }) => ({
      id,
      date: moment.parseZone(attendanceDate).format('MM/DD/YYYY'),
      checkIn: checkIn
        ? timezone
          ? moment(moment.parseZone(checkIn)).tz(timezone).format('HH:mm ')
          : moment.parseZone(checkIn).format('HH:mm ')
        : null,
      checkOut: checkOut
        ? timezone
          ? moment(moment.parseZone(checkOut)).tz(timezone).format('HH:mm ')
          : moment.parseZone(checkOut).format('HH:mm ')
        : null,
      locationType,
      excusedAbsence
    }))
    .reduce((acc, { date, ...session }) => {
      let attendanceGroup = acc[date];

      if (!attendanceGroup) {
        attendanceGroup = { date, sessions: [session] };
      } else {
        attendanceGroup.sessions.push(session);
      }

      return { ...acc, [date]: attendanceGroup };
    }, {});

  attendance = Object.values(attendance)
    .map(({ sessions, ...rest }) => {
      const absent = sessions.find(({ checkIn, checkOut }) => !checkIn && !checkOut);
      const hasOpenSessions = !!sessions.find(({ checkIn, checkOut }) => checkIn && !checkOut);

      return {
        ...rest,
        sessions,
        absent: absent ? { isExcused: absent?.excusedAbsence || false } : false,
        hasOpenSessions
      };
    })
    .sort((a, b) => {
      const aDate = moment(a.date);
      const bDate = moment(b.date);

      return aDate.isBefore(bDate) ? -1 : 1;
    });

  if (attendance.length) {
    while (attendance.length < 20) {
      const lastDate = attendance[attendance.length - 1].date;
      attendance.push({
        date: moment(lastDate).add(1, 'days').format('MM/DD/YYYY'),
        upcoming: true
      });
    }
  }

  return attendance;
};

const transformBeforeStudentUpdate = ({
  mailingAddress,
  phone,
  email,
  birthdate,
  gender,
  license,
  licenseState,
  graduationDate,
  cdlDate,
  permitExpiration,
  profilePicture,
  automaticGear
}) => {
  let data = {
    mailingAddress,
    phone,
    email,
    birthdate: birthdate ? dateToUnix(birthdate) : null,
    gender,
    license,
    licenseState,
    graduationDate: graduationDate ? dateToUnix(graduationDate) : null,
    cdlDate: cdlDate ? dateToUnix(cdlDate) : null,
    profilePicture: profilePicture && !isUrl(profilePicture) ? profilePicture.split(',')[1] : null,
    automaticGear: automaticGear ? true : false
  };
  data = stripNullObj(data);

  return data;
};

const transformTestingQuestions = ({ data = {} }) => data.testingQuestions;

const convertTime = num => {
  var hrs = parseInt(Number(num));
  var min = Math.round((Number(num) - hrs) * 60);
  return hrs + ':' + min;
};

const transformProfile = ({ data = {} }) => {
  const token = PersistentRepo.get('token');
  let student = data.studentCard ? data.studentCard.reportCard : {};

  const hoursDriven =
    student?.hbdInfo[0] == null
      ? 0
      : student.hbdInfo.reduce((a, b) => a + (b['roadHours'] || 0), 0);

  const studentEvaluations = (student?.hbdInfo[0] == null ? [] : student?.hbdInfo || []).reduce(
    (acc, item) => {
      return {
        ...acc,
        [moment(item.hoursBreakdownDate).format('MM/DD/YYYY')]: item.roadHours
      };
    },
    {}
  );

  student = {
    ...student,
    profilePicture: student.profilePicture
      ? `${GLOBALS.BASE_API}/students/profile_picture/${student.profilePicture}/${token}`
      : student.profilePicture,
    totalHoursDriven: convertTime(hoursDriven),
    studentEvaluations
  };
  return student;
};

const transformPerformance = ({ data = {} }) => (data.performance ? data.performance : {});

const transformComment = ({ data = {} }) => {
  const { testingComments } = data;

  let allComments = (testingComments || [])
    .map(({ id, comment, createdAt, instructor }) => ({
      id,
      comment,
      createdAt: moment.parseZone(createdAt),
      instructor
    }))
    .sort((a, b) => {
      const aDate = moment(a.createdAt);
      const bDate = moment(b.createdAt);
      return aDate.isBefore(bDate) ? 1 : -1;
    });
  return allComments;
};

const transformMiles = ({ data }) => {
  const studentEvaluations = (data?.studentEvaluations || []).reduce(
    (acc, { evaluationDate, details }) => {
      const previousHours = acc[moment(evaluationDate).format('MM/DD/YYYY')] || [];
      return {
        ...acc,
        [moment(evaluationDate).format('MM/DD/YYYY')]: [...previousHours, ...(details.trucks || [])]
      };
    },
    {}
  );

  const total = Object.values(studentEvaluations)
    .flat(10)
    .reduce(
      (acc, { odometerStart, odometerEnd }) =>
        acc + parseFloat(odometerEnd) - parseFloat(odometerStart),
      0
    );

  const totalHours = Object.values(studentEvaluations)
    .flat(10)
    .reduce((acc, { hoursDriven = '00:00' }) => {
      if (!hoursDriven.includes(':')) {
        hoursDriven = '00:00';
      }

      let [accHours, accMin] = acc.split(':');
      accHours = parseInt(accHours);
      accMin = parseInt(accMin);

      let [hours, min] = hoursDriven.split(':');
      hours = parseInt(hours);
      min = parseInt(min);

      accHours += hours;
      accMin += min;

      if (accMin >= 60) {
        accHours += Math.floor(accMin / 60);
        accMin = accMin % 60;
      }

      accHours = String(accHours).padStart(2, '0');
      accMin = String(accMin).padStart(2, '0');

      return `${accHours}:${accMin}`;
    }, '00:00');

  return { ...studentEvaluations, total, totalHours };
};

const transformSponsors = ({ data }) => {
  return data.sponsors ? data.sponsors.map(sponsor => ({ id: sponsor, label: sponsor })) : [];
};

const transformLocations = branchesRes => {
  return (branchesRes.data?.branches || []).reduce((acc, { id, name }) => {
    let items = [
      {
        id,
        label: `${name} Branch`
      }
    ];
    return [...acc, ...items];
  }, []);
};

const transformStatistics = ({ data }) => {
  const {
    roadsScore = 0,
    skillsScore = 0,
    pretripsScore = 0,
    currentStudents = 0,
    graduatedStudents = 0,
    avgHrs = 0,
    totalHrsYard = 0,
    totalHrsClassroom = 0,
    ...rest
  } = stripNullObj(data.studentsStatistics[0]);

  return {
    ...rest,
    avgHrs: Number(avgHrs).toFixed(2),
    totalHrs: `${Number(Number(totalHrsClassroom).toFixed(2))} / ${Number(
      Number(totalHrsYard).toFixed(2)
    )}`,
    roadsScore: `${(Number(roadsScore) * 100).toFixed(0)}%`,
    skillsScore: `${(Number(skillsScore) * 100).toFixed(0)}%`,
    pretripsScore: `${(Number(pretripsScore) * 100).toFixed(0)}%`,
    studentStatus: `${Number(currentStudents)}/${Number(graduatedStudents)}`
  };
};

const transformDuration = ({ checkIn, checkOut }) => {
  const checkInTime = moment(checkIn);
  const checkOutTime = moment(checkOut);
  if (checkOut) {
    let dif = checkOutTime.diff(checkInTime, 'minutes');
    return dif < 60
      ? dif + ' minutes'
      : moment.utc(moment.duration(dif, 'minutes').asMilliseconds()).format('HH:mm');
  }

  return '0';
};

const transformNotes = ({ checkInNotes, checkOutNotes, notes }) => {
  let allNotes = '';
  checkInNotes ? (allNotes = checkInNotes) : allNotes;
  checkOutNotes ? (allNotes += ', ' + checkOutNotes) : allNotes;
  if (notes) {
    if (allNotes) {
      allNotes += ', ' + notes;
    } else {
      allNotes = notes;
    }
  }

  return allNotes;
};

const transformStudentNotes = ({ data }) => {
  const { notes } = data;
  let allNotes = notes
    ? notes
        .map(({ id, note, createdAt, studentId, instructor, fmcsa }) => ({
          id,
          note,
          createdAt: moment.parseZone(createdAt),
          studentId,
          instructor,
          fmcsa
        }))
        .sort((a, b) => {
          const aDate = moment(a.createdAt);
          const bDate = moment(b.createdAt);
          return aDate.isBefore(bDate) ? 1 : -1;
        })
    : [];
  return allNotes;
};

const transformStudentSchedule = ({ data: { student } }) => {
  const {
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
    weekendClass,
    nightClass,
    grade,
    id,
    firstName,
    lastName,
    birthdate,
    license,
    branchId,
    locationType,
    sponsor = '',
    dcRegistration,
    dcRegistrationRequestDate,
    permitRecDate,
    cdlClass,
    licenseState,
    completionDate,
    totalSkills,
    totalRoad,
    classSubmittedToFmsca
  } = student;
  return {
    days: {
      monday: monday || false,
      tuesday: tuesday || false,
      wednesday: wednesday || false,
      thursday: thursday || false,
      friday: friday || false,
      saturday: saturday || false,
      sunday: sunday || false,
      weekendClass,
      nightClass
    },
    grade,
    studentInfo: {
      id,
      name: `${firstName} ${lastName}`,
      birthdate: birthdate ? moment(birthdate).parseZone().format('MM/DD/YYYY') : null,
      license,
      branchId,
      locationType: locationType === 2 ? 'Classroom' : 'Yard',
      sponsor,
      dcRegistration,
      dcRegistrationRequestDate,
      permitRecDate: permitRecDate ? moment(permitRecDate).parseZone().format('MM/DD/YYYY') : null,
      completionDate: completionDate
        ? moment(completionDate).parseZone().format('MM/DD/YYYY')
        : null,
      cdlClass,
      licenseState,
      totalSkills,
      totalRoad,
      classSubmittedToFmsca
    }
  };
};

const transformStudentById = ({ data: { student } }) => {
  const token = PersistentRepo.get('token');

  const {
    email,
    gender,
    birthdate,
    phone,
    contactNumber,
    ssn,
    programType,
    status,
    license,
    licenseState,
    dateClassBegins,
    permitRecDate,
    permitExpiration,
    testingDate,
    cdlDate,
    sponsor,
    branchId,
    locationType,
    automaticGear,
    totalHoursDriven,
    studentEvaluations,
    totalHoursTrainedYard,
    totalHoursTrainedClassroom,
    totalRoad,
    totalSkills,
    profilePicture,
    id,
    yardMovement
  } = student || {};

  const lastYardMovement = yardMovement?.[0]?.[0]?.historical_yard_movement?.[0] || null;
  const historicalMovement = yardMovement?.[0]?.[0]?.historical_yard_movement?.[1];
  const prevYardMovement = historicalMovement?.branch_name
    ? `from ${historicalMovement.branch_name}`
    : '';

  const yardMovementNote = lastYardMovement
    ? {
        id: lastYardMovement.id,
        note: `${lastYardMovement.student_name} moved ${prevYardMovement} to ${lastYardMovement.branch_name} yard.`,
        createdAt: lastYardMovement.moved_date,
        instructor: lastYardMovement.instructor_name,
        fmcsa: true
      }
    : {};

  return {
    email,
    gender,
    birthdate,
    phone,
    contactNumber,
    ssn,
    programType,
    status,
    license,
    licenseState,
    dateClassBegins,
    permitRecDate,
    permitExpiration,
    testingDate,
    cdlDate,
    sponsor,
    branchId,
    locationType,
    automaticGear,
    totalHoursDriven,
    studentEvaluations,
    totalHoursTrainedYard,
    totalHoursTrainedClassroom,
    totalRoad,
    totalSkills,
    profilePicture: profilePicture
      ? `${GLOBALS.BASE_API}/students/profile_picture/${profilePicture}/${token}`
      : profilePicture,
    id,
    yardMovement: yardMovementNote
  };
};

export {
  transformAll,
  transformStudentAttendance,
  transformBeforeStudentUpdate,
  transformTestingQuestions,
  transformProfile,
  transformPerformance,
  transformMiles,
  transformSponsors,
  transformLocations,
  transformStatistics,
  transformDuration,
  transformNotes,
  transformComment,
  transformStudentNotes,
  transformStudentSchedule,
  transformStudentById
};
