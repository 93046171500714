import React, { useContext, useEffect, useState, useRef } from 'react';
import { Context, useGet, useSave } from 'store';
import { Card, notification, DatePicker } from 'antd';
import moment from 'moment';
import { Form } from 'form';

import { Override, Absent } from '_src/modules/Attendance/components';
import { Table, Modal, Fields } from '_src/components';
import services from '_src/services';
import { PageContainer } from '_src/shared/styles';
import { PersistentRepo } from '_src/shared/repo';
import { Select } from '_src/shared/form-helpers';
import { Button } from '_src/ui';
import { Refresh } from '_src/components';
import StudentTimeline from '_src/modules/Students/components/Timeline/StudentTimeline';
import { FilterRow, ButtonContainer, Row } from './styles';

var timezoneParam = moment().utcOffset() / 60;

if (timezoneParam > 0) {
  timezoneParam = 'GMT ' + timezoneParam;
} else if (timezoneParam < 0) {
  timezoneParam = 'GMT' + timezoneParam;
} else if (timezoneParam === 0) {
  timezoneParam = 'GMT 0';
}
const buttonColor = GLOBALS.colors;
const resource = 'adminAttendance';
const attendanceConfig = services.attendance;
const config = services[resource];

export default () => {
  const [getTimezone, setTimezone] = useState();
  const fmt = 'MM/DD/YYYY HH:mm';
  const getBranchTimezone = PersistentRepo.get('getTimezone');
  const allBranchesLocation = PersistentRepo.get('allBranches');
  const initialLocation = allBranchesLocation
    ? allBranchesLocation
    : PersistentRepo.get('location', 'allxallx1');

  const { getData, getShown, setShown, setData, refreshResource } = useContext(Context);
  const [location, setLocation] = useState(initialLocation);
  const [sponsor, setSponsor] = useState('all');
  const [selected, setSelected] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [otherStudents, setOtherStudents] = useState([]);
  const [mode, setMode] = useState();
  const [selectedGroup, setGroup] = useState('all');
  const [attendanceLink, setAttendanceLink] = useState(null);
  const [studentOverride, setStudentOverride] = useState();
  const [classType, setClassType] = useState(
    PersistentRepo.get('day') ? PersistentRepo.get('day') : ['all']
  );
  const [date, setDate] = useState(moment());
  const formApi = useRef();
  const { save: createAttendance, busy: busyCreate } = useSave(config.create);
  const { save: updateAttendance, busy: busyUpdate } = useSave(config.update);
  const { save: generateAttendanceLink, busy: busyGenerateAttendanceLink } = useSave(
    config.generateAttendanceLink
  );

  const { save: create } = useSave(attendanceConfig.create);
  const { save: edit } = useSave(attendanceConfig.edit);

  const getAttendanceParams = () => {
    let daysFilter = classType
      .filter(day => day !== 'all')
      .reduce((acc, curr) => ((acc[curr] = 1), acc), {});

    if (daysFilter.dayClass) {
      delete daysFilter.dayClass;
      daysFilter.nightClass = false;
    }
    const [locationType, locationId, branchId] = location.split('x');

    let attendanceParams = {
      date: moment(date).format('DD-MM-YYYY'),
      locationType: 0,
      locationId: branchId,
      timezone: timezoneParam,
      ...daysFilter,
      ...(selectedGroup && selectedGroup != 'all' && { groups: selectedGroup })
    };

    if (locationType !== 'all') {
      attendanceParams.locationType = locationType === 'yard' ? 1 : 2;
      attendanceParams.locationId = locationId;
    }

    if (sponsor !== 'all') {
      attendanceParams.sponsors = sponsor;
    }

    return attendanceParams;
  };

  const { data, busy: loading } = useGet({
    ...attendanceConfig.get,
    params: getAttendanceParams()
  });

  useEffect(() => {
    const allLocations = getData('locations');

    if (allLocations && location) {
      let timezone = allLocations
        ? allLocations.find(({ id }) => id === location)
          ? allLocations.find(({ id }) => id === location).timezone
          : ''
        : '';
      setTimezone(timezone);

      if (!allLocations.find(({ id }) => id === location)) {
        onLocationChange(allLocations[0]?.id);
      }
    }
  });

  useEffect(() => {
    setSelected([]);

    setSelectedStudents([]);
    const checkedInStudents = data.filter(({ attendance }) => {
      const { checkIn, checkOut } = attendance;
      return !!checkIn && !checkOut;
    });
    setSelected([...checkedInStudents.map(({ id }) => id)]);
    setSelectedStudents([...checkedInStudents]);
  }, [data]);

  const onLocationChange = loc => {
    setLocation(loc);

    PersistentRepo.set('location', loc);
    PersistentRepo.delete('FmcsaSubmissionsLocation');
  };

  const closeModal = () => {
    setShown(resource, false);
  };

  const openModal = modalMode => {
    const selectedStudents = (data || []).filter(({ studentId }) => selected.includes(studentId));

    const notAbleToCheck = selectedStudents.filter(({ attendance }) => {
      const { checkIn, excusedAbsence } = attendance;
      return modalMode === 'checkIn' ? !!checkIn || excusedAbsence !== undefined : !checkIn;
    });

    if (notAbleToCheck.length) {
      const names = notAbleToCheck.map(({ name }) => name).join(', ');
      const message =
        modalMode === 'checkIn'
          ? `${names} ${notAbleToCheck.length === 1 ? 'is' : 'are'} already checked in`
          : `${names} need to be checked in before you can check ${
              notAbleToCheck.length === 1 ? 'him' : 'them'
            } out`;

      notification.warning({ message, duration: 5 });
    } else {
      setMode(modalMode);
      setShown(resource);
    }
  };

  const checkOverlappingCheckin = (value, studentId) => {
    const studentAttendances = (selectedStudents || []).find(
      student => student.id === studentId
    ).attendances;
    if (
      studentAttendances.length !== 0 &&
      studentAttendances[studentAttendances.length - 1].checkOut
    ) {
      let current = moment.tz(moment.parseZone(value).format(fmt), fmt, getTimezone);
      let past = moment.tz(
        moment.parseZone(studentAttendances[studentAttendances.length - 1].checkOut).format(fmt),
        fmt,
        getTimezone
      );
      return current <= past;
    } else {
      return false;
    }
  };

  const checkSessionDuration = (checkIn, checkOut) => {
    var durationDiff = moment(checkOut)
      .set('seconds', 0)
      .diff(moment(moment.parseZone(checkIn).format(fmt)).set('seconds', 0), 'minutes');
    if (durationDiff < 20 || durationDiff > 600) {
      return false;
    } else {
      return true;
    }
  };

  const generateLink = () => {
    if (attendanceLink) {
      navigator.clipboard.writeText(attendanceLink).then(() => {
        notification.success({
          message: 'The attendance link has been copied to your clipboard',
          duration: 5
        });
      });
    } else {
      generateAttendanceLink({}).then(res => {
        if (res.success) {
          navigator.clipboard.writeText(res.data.generatedLink).then(() => {
            notification.success({
              message: 'The attendance link has been created and copied to your clipboard',
              duration: 5
            });
          });
          setAttendanceLink(res.data.generatedLink);
        } else {
          notification.error({
            message: 'Generating attendance link failed',
            duration: 5
          });
        }
      });
    }

    setTimeout(() => {
      setAttendanceLink(null);
    }, 10000);
  };

  const buttons = () => (
    <ButtonContainer>
      <Button
        filled={true}
        color={buttonColor.ORANGE}
        text="CHECKIN"
        height={30}
        width={115}
        disabled={!selected.length}
        onClick={() => openModal('checkIn')}
        alignSelf="flex-end"
      />
      <Button
        filled={false}
        color={buttonColor.ORANGE}
        text="CHECKOUT"
        height={30}
        width={115}
        disabled={!selected.length}
        onClick={() => openModal('checkOut')}
        alignSelf="flex-end"
      />

      {/* <Button
        filled={true}
        color={buttonColor.ORANGE}
        text={attendanceLink ? 'Copy Link' : 'Attendance Link'}
        height={30}
        width={150}
        onClick={() => generateLink()}
        alignSelf="flex-end"
        loading={busyGenerateAttendanceLink}
      /> */}
    </ButtonContainer>
  );

  const submitAttendance = () => {
    const fmt = 'MM/DD/YYYY HH:mm';
    let utcTime = moment.parseZone(moment(date)).format(fmt);
    let nowMoment = moment.tz(utcTime, fmt, getTimezone).unix();

    if (formApi.current.validate()) {
      const { time } = formApi.current.values();
      let dataToSubmit = [];
      let overlappedStudents = [];
      let sessionDurationStudents = [];
      const request = mode === 'checkIn' ? createAttendance : updateAttendance;

      if (mode === 'checkIn') {
        const [locationType, locationId, branchId] = location.split('x');
        const allLocations = getData('locations');
        const allStudents = data.reduce(
          (acc, { id, locationType }) => ({ ...acc, [id]: locationType }),
          {}
        );

        let utcTimeCheckIn = moment.parseZone(moment(time)).format(fmt);
        let checkInMoment = moment.tz(utcTimeCheckIn, fmt, getTimezone).unix();

        (selectedStudents || []).map(student => {
          if (checkOverlappingCheckin(moment(time), student.id)) {
            overlappedStudents.push(student.name);
          }
        });

        dataToSubmit = selected.map(studentId => {
          let attendance = {
            studentId,
            locationId,
            locationType: locationType === 'yard' ? 1 : 2,
            branchId,
            attendanceDate: nowMoment,
            checkIn: checkInMoment,
            manual: true,
            bulkAttendance: true,
            isHazmat: Boolean(
              selectedStudents.filter(({ id }) => id === studentId)[0]?.endorsementCode === 'H'
            )
          };

          if (locationType === 'all') {
            const studentLocationType = allStudents[studentId];
            const studentLocationId = allLocations.find(loc => loc.branchId == branchId)
              .branchLocation[studentLocationType === 1 ? 'yardId' : 'classroomId'];

            attendance.locationId = studentLocationId;
            attendance.locationType = studentLocationType;
          }

          return attendance;
        });
      } else if (mode === 'checkOut') {
        selectedStudents.map(({ name, attendance }) => {
          let utcTimeCheckOut = moment.parseZone(moment(time)).format(fmt);
          let momentCheckout = moment(utcTimeCheckOut);
          if (moment(attendance.checkIn).valueOf() > momentCheckout.valueOf()) {
            momentCheckout.add(1, 'days');
          }

          let checkOutMoment = moment.tz(momentCheckout.format(fmt), fmt, getTimezone).unix();

          if (checkSessionDuration(attendance.checkIn, momentCheckout.format(fmt))) {
            dataToSubmit.push({
              attendanceId: attendance?.id,
              checkOut: checkOutMoment,
              manual: true
            });
          } else {
            sessionDurationStudents.push(name);
          }
        });
      }

      if (overlappedStudents.length > 0) {
        notification.error({
          message: `${overlappedStudents.join(', ')} ${
            overlappedStudents.length === 1 ? 'has' : 'have'
          } duplicate sessions`,
          duration: 5
        });
      } else if (sessionDurationStudents.length > 0) {
        notification.error({
          message: `Session durations can not be less than 20 minutes or more than 10 hours: ${sessionDurationStudents.join(
            ', '
          )}`,
          duration: 5
        });
      } else {
        request({ attendance: dataToSubmit }).then(({ success }) => {
          if (success === 1) {
            refreshResource('attendance');
            setSelected([]);
            setSelectedStudents([]);
            closeModal();

            notification.success({
              message: `Students have been ${mode === 'checkIn' ? 'checked in' : 'checked out'}.`,
              duration: 5
            });

            if (mode === 'checkIn') {
              const otherStudents = (data || []).filter(
                ({ studentId }) => !selected.includes(studentId)
              );
              setOtherStudents(otherStudents);
              setShown('absence');
            }
          } else {
            notification.error({
              message: 'Failed to save attendance',
              duration: 5
            });
          }
        });
      }
    }
  };

  // duplicate from attendance
  const saveAbsentStudents = (absent = []) => {
    const [locationType, locationId, branchId] = location.split('x');
    const allLocations = getData('locations');
    const allStudents = getData('attendance').reduce(
      (acc, { id, locationType }) => ({ ...acc, [id]: locationType }),
      {}
    );

    const requests = absent.map(({ id, isExcused, attendanceId, endorsementCode }) => {
      let utcTimeNow = moment.parseZone(moment()).format(fmt);
      let data = {
        locationId,
        locationType: locationType === 'yard' ? 1 : 2,
        branchId,
        attendanceDate: moment.tz(utcTimeNow, fmt, getBranchTimezone).unix(),
        excusedAbsence: isExcused,
        isHazmat: Boolean(endorsementCode === 'H')
      };

      if (locationType === 'all') {
        const studentLocationType = allStudents[id];
        const studentLocationId = allLocations.find(loc => loc.branchId == branchId).branchLocation[
          studentLocationType === 1 ? 'yardId' : 'classroomId'
        ];

        data.locationId = studentLocationId;
        data.locationType = studentLocationType;
      }

      if (attendanceId) {
        return edit({ excusedAbsence: isExcused, id: attendanceId });
      } else {
        return create({ ...data, studentId: id });
      }
    });

    Promise.all(requests).then(responses => {
      if (responses.length && responses.every(resp => resp.success === 1)) {
        refreshResource('attendance');
        notification.success({
          message: `Successfully marked as absent`,
          duration: 5
        });
      }
    });
  };
  config.table.find(({ dataIndex }) => dataIndex === 'name').onCell = student => ({
    onClick: () => {
      const allLocations = getData('locations');

      const branchName = allLocations
        ? allLocations.find(loc => student.branchId === loc.branchId).label.split(' ')[0]
        : '';

      const studentBranch = allLocations
        ? allLocations.find(loc => student.branchId === loc.branchId).branchId
        : '';

      setData('studentTimeline', {
        ...student,
        branchId: branchName,
        studentBranch: studentBranch
      });
      setShown('studentTimeline');
    }
  });

  config.table.find(({ id }) => id === 'sessions').onCell = student => ({
    onClick: () => {
      setStudentOverride(student);
      setShown('override');
    }
  });

  return (
    <PageContainer>
      <Refresh />
      <Card title="Manage attendance">
        <Row>
          <Select
            showSearch
            onChange={onLocationChange}
            {...attendanceConfig.location}
            value={location}
            label="Filter by Branch"
          />

          <div>
            <span>Filter by date</span>
            <DatePicker
              label="Date"
              allowClear={false}
              value={date}
              onChange={value => {
                setDate(value);
              }}
            />
          </div>

          <Select
            showSearch
            onChange={setSponsor}
            {...config.sponsors}
            value={sponsor}
            options={[{ id: 'all', label: 'All' }]}
          />

          <Select
            options={[
              { id: 'all', label: 'All' },
              { id: 'monday', label: 'Monday' },
              { id: 'tuesday', label: 'Tuesday' },
              { id: 'wednesday', label: 'Wednesday' },
              { id: 'thursday', label: 'Thursday' },
              { id: 'friday', label: 'Friday' },
              { id: 'saturday', label: 'Saturday' },
              { id: 'sunday', label: 'Sunday' },
              { id: 'night_class', label: 'Night class' },
              { id: 'weekend_class', label: 'Weekend class' },
              { id: 'dayClass', label: 'Day Class' },
              { id: 'part_time', label: 'Part-Time Class' },
              { id: 'half_day_am', label: 'Half-day AM' },
              { id: 'half_day_pm', label: 'Half-day PM' }
            ]}
            mode="multiple"
            label="Filter by Day"
            value={classType}
            showArrow={false}
            placeholder="Select a day"
            onChange={e => {
              const lastSelection = e.length - 1;
              const all = e.filter(s => s === 'all' || s === 'dayClass');
              let classSelected = [...e];

              if (all.length > 0) {
                if (e[lastSelection] === 'all') {
                  classSelected = all;
                } else {
                  classSelected = e.filter(s => s !== 'all');
                }
              }
              PersistentRepo.set('day', classSelected.length === 0 ? ['all'] : [...classSelected]);
              setClassType(classSelected.length === 0 ? ['all'] : [...classSelected]);
            }}
          />

          <Select
            options={[
              { id: 'all', label: 'All' },
              { id: 'Group 1', label: 'Group 1' },
              { id: 'Group 2', label: 'Group 2' },
              { id: 'Group 3', label: 'Group 3' },
              { id: 'Group 4', label: 'Group 4' },
              { id: 'Group 5', label: 'Group 5' },
              { id: 'Group 6', label: 'Group 6' },
              { id: 'Group 7', label: 'Group 7' },
              { id: 'Group 8', label: 'Group 8' },
              { id: 'Group 9', label: 'Group 9' },
              { id: 'Group 10', label: 'Group 10' },
              { id: 'Group 11', label: 'Group 11' },
              { id: 'Group 12', label: 'Group 12' }
            ]}
            label="Filter by Groups"
            showArrow
            value={selectedGroup}
            onChange={group => setGroup(group)}
          />
        </Row>
        {buttons()}
        <Table
          columns={config.table}
          data={data}
          loading={loading}
          rowSelection={{
            selectedRowKeys: selected,
            onChange: setSelected,
            onSelect: (record, selectedValue, selectedRows, nativeEvent) => {
              setSelectedStudents(selectedRows);
            },
            onSelectAll: (selected, selectedRows, changeRows) => {
              setSelectedStudents(selectedRows);
            }
          }}
        />

        <Modal
          title="Manage attendance"
          visible={getShown(resource)}
          onCancel={closeModal}
          onOk={submitAttendance}
          okText="Submit"
          confirmLoading={busyCreate || busyUpdate}
        >
          <Form formApi={api => (formApi.current = api)} schema={config.modal.schema}>
            <Fields fields={config.modal.fields} />
          </Form>
        </Modal>

        {getShown('override') && (
          <Override student={studentOverride} onModalClose={() => setStudentOverride(null)} />
        )}

        {getShown('absence') && <Absent save={saveAbsentStudents} students={otherStudents} />}

        {getShown('studentTimeline') && <StudentTimeline rerenderEvaluations={() => {}} />}
      </Card>
    </PageContainer>
  );
};
