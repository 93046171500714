import React, { useRef, useState } from 'react';
import { Select, Form } from 'antd';
import styled from 'styled-components';
import { Request } from 'store';

import services from '_src/services';
import { reportTypesDescriptions } from '_src/constants';

const Label = styled.div`
  margin-bottom: ${({ inline }) => (inline ? '0' : '5px')};
  margin-right: ${({ inline }) => (inline ? '5px' : '0')};
  min-width: ${({ inline }) => (inline ? '30%' : '0')};
  color: ${({ hasError }) => (hasError ? '#f5222d' : '')};
`;

const Container = styled.div`
  display: ${({ inline }) => (inline ? 'flex' : 'block')};
  align-items: center;
`;

const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #e6f7e9;
  padding: 10px;
  margin-top: 10px;
  border-radius: 4px;
`;

export default ({
  value,
  error,
  onChange,
  touched,
  label,
  options = [],
  labelKey = 'label',
  valueKey = 'id',
  async = false,
  resource = '',
  get,
  placeholder,
  isGrouped,
  hide = [],
  inline = false,
  mode,
  hasOptions = true,
  showSearch = false,
  dataCy = '',
  disabled = false,
  pagination = false,
  showDescription = false,
  ...rest
}) => {
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);

  const selectRef = useRef(null);
  const hasError = error && touched;
  const selectProps = {
    ...rest,
    mode,
    placeholder: placeholder || label,
    onChange: value => onChange(value)
  };

  const getValue = data => {
    if (!hasOptions) {
      return value || [];
    }

    if (['multiple', 'tags'].includes(mode) && (data.length === 0 || !value)) {
      return [];
    }

    return data.length > 0 ? value : null;
  };

  const renderSelectItem = item => (
    <Select.Option
      key={item[valueKey]}
      value={item[valueKey]}
      data-search={item[labelKey]}
      data-cy={'option-select-' + name}
      disabled={item.disabled}
    >
      {item[labelKey]}
    </Select.Option>
  );

  const renderSelect = (data, extraProps = {}) => {
    data = data.filter(item => !hide.includes(item[valueKey]));

    if (isGrouped) {
      return (
        <Select {...extraProps} {...selectProps} value={getValue()} data-cy={dataCy}>
          {data.map(({ label, items, id }) => (
            <Select.OptGroup label={label} key={id} data-cy={'option-select-' + name}>
              {items.map(item => renderSelectItem(item))}
            </Select.OptGroup>
          ))}
        </Select>
      );
    }

    return (
      <Select
        disabled={disabled}
        ref={selectRef}
        // allowClear
        showSearch={showSearch}
        {...extraProps}
        {...selectProps}
        data-cy={dataCy || 'field-select-' + name}
        value={getValue(data)}
        filterOption={(input, { props }) =>
          props['data-search'].toLowerCase().includes(input.toLowerCase())
        }
        onPopupScroll={e => {
          e.persist();
          let target = e.target;
          if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (limit + 10 <= total) {
              setLimit(limit + 10);
            }
          }
        }}
      >
        {data.map(item => renderSelectItem(item))}
      </Select>
    );
  };

  const renderAsyncSelect = () => {
    const config = get ? get : services[resource].get;

    return (
      <Request.Get memoize {...config} params={pagination && { limit: limit }}>
        {({ data, busy }) => {
          pagination && setTotal(data.total);
          const dataOptions = pagination ? (data.data ? data.data : []) : data;

          return renderSelect([...options, ...dataOptions], { loading: busy });
        }}
      </Request.Get>
    );
  };

  return (
    <Form.Item validateStatus={hasError ? 'error' : ''}>
      <Container inline={inline}>
        {(label || hasError) && (
          <Label
            inline={inline}
            hasError={hasError}
            onClick={() => {
              if (selectRef.current) {
                selectRef.current.rcSelect.selectionRef.click();
              }
            }}
          >
            {hasError ? error : label}
          </Label>
        )}
        {async ? renderAsyncSelect() : renderSelect(options)}
      </Container>
      {showDescription && value && label === 'Report Type' && (
        <DescriptionContainer>
          {reportTypesDescriptions.find(desc => desc.id === value).description}
        </DescriptionContainer>
      )}
    </Form.Item>
  );
};
