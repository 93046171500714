import React, { useState, useContext, useEffect } from 'react';
import { Request, Context, useSave, useFetch } from 'store';
import { Form } from 'form';
import { Button, Skeleton, Popconfirm, Icon, notification } from 'antd';

import { Modal, Fields } from '_src/components';
import services from '_src/services';
import { Restricted } from '_src/shared/restrict';
import { PersistentRepo } from '_src/shared/repo';

import { FieldsContainer } from './styles';

export default ({
  resource,
  closeModal,
  initialValues = {},
  children,
  onSave,
  showCancel = true,
  showDelete = true,
  showSave = true,
  saveText = 'Submit',
  refreshTable = {},
  confirmSave,
  ...rest
}) => {
  const { getShown, getData, refreshResource, setShown, setData } = useContext(Context);
  const [formApi, setFormApi] = useState();
  const config = services[resource];
  const configFmcsaSubmissions = services['fmcsaSubmissions'];
  const { modal } = config;
  const { id } = initialValues;
  const mode = id ? 'edit' : 'create';
  const schema = mode === 'edit' ? modal.schema.edit : modal.schema.create;
  const { save: editStudent } = useSave(configFmcsaSubmissions.edit);
  const { get: getUser } = useFetch({ ...services['users'].getUser });

  useEffect(() => {
    if (confirmSave) editStudent(formApi.values()).then(handleChange);
  }, [confirmSave]);

  const handleChange = ({ success, data }, type) => {
    if (typeof refreshTable === 'function') {
      refreshTable(false);
    }
    let message = '';

    if (success === 1) {
      if (type === 'delete') {
        message = 'Successfully deleted';
      } else {
        onSave && onSave();
        message = 'Successfully saved';
      }

      if (resource === 'users') {
        const { email, id } = getData('user');
        const userEmailToUpdate = formApi.values().email;

        if (email === userEmailToUpdate) {
          getUser({
            replace: {
              id: id
            }
          }).then(response => {
            const { data } = response[0];
            const privileges = data.user.privileges;

            setData('privileges', privileges);
            PersistentRepo.set('privileges', privileges);
          });
        }
      }

      if (typeof refreshTable === 'function') {
        refreshTable(true);
      }

      refreshResource(resource);
      closeModal();
      notification.success({
        message,
        duration: 3
      });
    } else {
      const errorMessage = data.message;
      if (type === 'delete') {
        message = `Failed to delete`;
      } else if (
        (resource === 'users' &&
          typeof errorMessage === 'string' &&
          errorMessage.includes('users_email_key')) ||
        errorMessage?.integrityErrorEmail.includes('Email already exists.')
      ) {
        message = 'A user with the same email already exists!';
      } else {
        message = 'Failed to save information';
      }

      notification.error({
        message,
        duration: 3
      });
    }
  };

  const renderDelete = () => {
    const { restrict = resource } = config;

    if (config.delete) {
      const deleteButton = (
        <Request.Delete key="delete" show={mode === 'edit'} {...config.delete}>
          {({ destroy, busy }) => (
            <Popconfirm
              title="Are you sure？"
              okType="danger"
              okText="Delete"
              loading={busy}
              onConfirm={() => {
                destroy({ id })
                  .then(data => handleChange(data, 'delete'))
                  .catch(err => {});
              }}
              icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            >
              <Button type="danger" loading={busy}>
                Delete
              </Button>
            </Popconfirm>
          )}
        </Request.Delete>
      );

      return restrict ? (
        <Restricted resource={restrict} method="DELETE" key="delete">
          {deleteButton}
        </Restricted>
      ) : (
        deleteButton
      );
    }
  };

  const renderSave = () => (
    <Request.Save
      {...(mode === 'edit' ? config.edit : config.create)}
      validate={() => {
        const isValid = formApi.validate();
        if (!isValid) {
          notification.warning({
            message: 'Please fill all the required fields in the form',
            duration: 3
          });
        }

        return isValid;
      }}
      key="submit"
    >
      {({ save, busy }) => (
        <Button
          type="primary"
          loading={busy}
          onClick={() => {
            const isValid = formApi.validate();
            if (resource === 'fmcsaSubmissions' && mode === 'edit' && isValid) {
              save(formApi.values())
                .then(handleChange)
                .catch(err => {
                  notification.error({
                    message: `Failed to save`,
                    duration: 3
                  });
                });
            } else {
              let payload = formApi.values();
              if (resource === 'skillQuestions') {
                const removedBranches = initialValues?.branchIds?.filter(
                  id => !payload.branchIds.includes(id)
                );
                payload = {
                  ...payload,
                  removedBranches: removedBranches
                };
              }

              if (!payload.hasOwnProperty('branchIds')) {
                payload.branchIds = [];
              }

              save(payload)
                .then(handleChange)
                .catch(err => {
                  notification.error({
                    message: `Failed to save`,
                    duration: 3
                  });
                });
            }
          }}
        >
          {saveText}
        </Button>
      )}
    </Request.Save>
  );

  const renderRestrictedSave = () => {
    const { restrict = resource, allowUpdate = true, allowCreate = true } = config;
    const isSubmittable = mode === 'edit' ? allowUpdate : allowCreate;

    if (!isSubmittable) {
      return null;
    }

    return restrict && isSubmittable ? (
      <Restricted
        resource={restrict}
        method={mode === 'edit' ? ['PUT', 'PATCH'] : 'POST'}
        key="submit"
      >
        {renderSave()}
      </Restricted>
    ) : (
      renderSave()
    );
  };

  const renderCancel = () => (
    <Button key="cancel" onClick={closeModal}>
      Cancel
    </Button>
  );

  const conditionallyFields = (fields, location, isHazmat) => {
    const isYard = location === 'Yard';

    return fields
      .filter(({ name }) => (isHazmat ? true : name !== 'branch' && name !== 'contactNumber'))
      .map(field => {
        const { name } = field;

        if (!isYard) {
          return name === 'skills' || name === 'road' ? { ...field, disabled: true } : field;
        }

        if (isYard) {
          return name === 'score' ? { ...field, disabled: true } : field;
        }
      });
  };

  const renderForm = (data = {}) => {
    const initial = { ...(modal.initial || {}), ...initialValues, ...data };
    const isFmcsaSubmissionsEdit = resource === 'fmcsaSubmissions' && mode === 'edit';
    let { fields, noColumns = 1 } = modal;

    fields = typeof fields === 'function' ? fields(initial) : fields;
    fields = isFmcsaSubmissionsEdit
      ? conditionallyFields(fields, initial.location, initial.endorsementCode === 'H')
      : fields;

    return (
      <FieldsContainer columns={noColumns}>
        <Form formApi={api => setFormApi(api)} initialValues={initial} schema={schema}>
          <Fields fields={fields} />
        </Form>
      </FieldsContainer>
    );
  };

  const renderBusyForm = () => <Skeleton active title={false} paragraph={{ rows: 5 }} />;

  const renderFooter = () => {
    const footerButtons = [];

    if (showCancel) {
      footerButtons.push(renderCancel());
    }

    if (showDelete) {
      footerButtons.push(renderDelete());
    }

    if (showSave) {
      footerButtons.push(renderRestrictedSave());
    }

    return footerButtons;
  };

  const renderModal = ({ data, busy } = {}) => {
    Object.entries(data || {}).map(([key, value]) => {
      if (!formApi.getField(key)) {
        formApi.setField(key, value);
      }
    });

    return (
      <Modal
        title={modal.title}
        visible={getShown(resource)}
        onCancel={closeModal}
        footer={renderFooter()}
        {...(modal.props || {})}
        {...rest}
      >
        {children}
        {busy ? renderBusyForm() : renderForm(data)}
      </Modal>
    );
  };

  if (!getShown(resource)) {
    return null;
  }

  return !modal.load ? (
    renderModal()
  ) : (
    <Request.Get {...modal.load} replace={initialValues}>
      {renderModal}
    </Request.Get>
  );
};
